import { useRef, useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  Autocomplete,
  TextField,
} from '@mui/material';
import moment from 'moment/moment';
import { useForm } from 'react-hook-form';
import DynamicForm from '../../../components/dynamic-form/DynamicForm';

const AddNewPartnerDialog = ({ open, onClose, onSubmit, searchedNumber }) => {
  const formRef = useRef(null);
  const [savedPartners, setSavedPartners] = useState([]);

  useEffect(() => {
    try {
      const saved = JSON.parse(localStorage.getItem('savedPartners') || '[]');
      setSavedPartners(saved);
    } catch (error) {
      console.error('Error loading saved partners:', error);
    }
  }, []);

  const handleClose = () => {
    if (formRef.current) {
      formRef.current.reset();
    }
    onClose();
  };

  const handleSubmit = (data) => {
    const formattedData = {
      isGuest: true,
      ...data,
      dob: moment(data.dob, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    };

    

     setSavedPartners(JSON.parse(localStorage.getItem('savedPartners') || '[]'));
     const existingPartnerIndex = savedPartners.findIndex(
       (partner) => partner.mobile === data.mobile
     );
 
     if (existingPartnerIndex >= 0) {
       savedPartners[existingPartnerIndex] = formattedData;
     } else {
       savedPartners.push(formattedData);
     }
     localStorage.setItem('savedPartners', JSON.stringify(savedPartners));

    onSubmit(formattedData);
    handleClose();
  };

  const formSchema = {
    name: 'partner_form',
    layout: 'single-column',
    groups: [
      {
        name: 'basic_details',
        label: { en: 'Basic Details' },
        fields: ['name', 'mobile', 'dob', 'gender','email'],
      },
      {
        name: 'additional_info',
        label: { en: 'Additional Information' },
        fields: ['academyNameAndPlace'],
      },
    ],
    fields: [
      {
        name: 'name',
        type: 'text',
        label: { en: 'Partner Name' },
        placeholder: { en: 'Enter partner name' },
        validations: {
          required: true,
          minLength: 2,
          maxLength: 50,
        },
      },
      {
        name: 'mobile',
        type: 'phone',
        label: { en: 'Mobile Number' },
        placeholder: { en: 'Enter mobile number' },
        validations: {
          required: true,
          pattern: '^\\+?[1-9]\\d{1,14}$',
        },
      },
      {
        name: 'dob',
        type: 'date',
        label: { en: 'Date of Birth' },
        placeholder: { en: 'Select date of birth' },
        validations: {
          required: true,
          max: moment().subtract(3, 'years').format('YYYY-MM-DD'),
        },
      },
      {
        name: 'gender',
        type: 'select',
        label: { en: 'Gender' },
        placeholder: { en: 'Select gender' },
        options: [
          { value: 'male', label: 'Male' },
          { value: 'female', label: 'Female' },
          { value: 'other', label: 'Other' },
        ],
        validations: {
          required: true,
        },
      },
      {
        name: 'email',
        type: 'email',
        label: { en: 'Email Address' },
        placeholder: { en: 'Enter email address' },
        validations: {
          required: false,
          email: true,
        },
      },
      {
        name: 'academyNameAndPlace',
        type: 'text',
        label: { en: 'Academy Name and Place' },
        placeholder: { en: 'Enter academy name and place' },
        validations: {
          required: true,
          minLength: 2,
          maxLength: 100,
        },
      },
    ],
  };

  const handlePartnerSelect = (partner) => {
    if (partner && formRef.current) {
      const formFields = formSchema.fields.map(field => field.name);
      
      formFields.forEach(fieldName => {
        let value = partner[fieldName];
        
        if (fieldName === 'dob' && value) {
          value = moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY');
        }
        
        formRef.current.setValue(fieldName, value || '');
      });
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Add New Partner</DialogTitle>
      <DialogContent>
        <Stack spacing={3} sx={{ mt: 1 }}>
          {savedPartners && savedPartners.length > 0 && (
            <Autocomplete
              options={savedPartners}
              getOptionLabel={(option) => `${option.name} (${option.mobile})`}
              onChange={(_, value) => handlePartnerSelect(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Saved Partners"
                  helperText="Select a previously saved partner or fill in new details below"
                />
              )}
            />
          )}
          <DynamicForm
            ref={formRef}
            formName="add-partner-form"
            onSubmit={handleSubmit}
            schema={formSchema}
            defaultValues={{
              mobile: searchedNumber || '',
            }}
            showSubmitButton={false}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button onClick={handleClose} color="inherit">
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            if (formRef.current) {
              formRef.current.submitForm();
            }
          }}
          sx={{ minWidth: 120 }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddNewPartnerDialog;