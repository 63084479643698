import React from 'react';
import { Box, Typography } from '@mui/material';
import Iconify from '../../components/Iconify';

const TournamentNotices = ({ data }) => {
  const notices = data?.props?.find(p => p.key === 'notice')?.data;

  return (
    <Box
      sx={{
        bgcolor: 'info.lighter',
        p: 3,
        borderRadius: 1,
        display: 'flex',
        gap: 1,
        textAlign: 'left',
      }}
    >
      <Iconify
        icon="mdi:information"
        sx={{
          color: 'info.main',
          width: 24,
          height: 24,
          flexShrink: 0,
          mt: 0.5,
        }}
      />
      <Box>
        {notices?.map((notice, index) => (
          <React.Fragment key={index}>
            <Typography variant="body2" component="span" color="info.dark">
              {notice.description}
            </Typography>
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};

export default TournamentNotices;
