import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(number) {
  if (number === null || number === undefined) {
    return '₹0';
  }
  
  // Convert to rupees from paise
  const rupees = number / 100;
  const isNegative = rupees < 0;
  const absRupees = Math.abs(rupees);
  
  // Format with Indian numbering system
  const formatted = numeral(absRupees).format('0,0.00');
  
  // Remove trailing zeros after decimal
  const cleanNumber = formatted.replace(/\.?0+$/, '');
  
  // Add minus sign before ₹ if negative
  return isNegative ? `-₹ ${cleanNumber}` : `₹ ${cleanNumber}`;
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return numeral(number).format('0.00a').replace('.00', '');
}

export function fDate(timestamp) {
  return new Date(timestamp * 1000).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
}

export function fData(inputValue) {
  if (!inputValue) return '';

  if (inputValue === 0) return '0 Bytes';

  const units = ['bytes', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'];

  const decimal = 2;

  const baseValue = 1024;

  const number = Number(inputValue);

  const index = Math.floor(Math.log(number) / Math.log(baseValue));

  const fm = `${parseFloat((number / baseValue ** index).toFixed(decimal))} ${units[index]}`;

  return fm;
}


