// Function: Eligibility

import React, { forwardRef } from 'react';
import { Box, Card, Typography, List, ListItem } from '@mui/material';

const Eligibility = forwardRef(({ eligibilityData }, ref) => (
    <Card ref={ref} sx={{ m: {xs: 0, md:4}, px: { xs:1, md: 8 }, py: {xs:2,md:4}, bgcolor: 'theme.palette.background.paper', mb:{xs:3}}}>
        <Typography variant="h6" component="h2" gutterBottom>
            Eligibility Criteria
        </Typography>

        <List sx={{ listStyleType: 'disc', pl: 4 }}>
            {eligibilityData?.data?.map((data, index) => (
                <ListItem 
                    key={index} 
                    sx={{ 
                        display: 'list-item',
                        p: 0,
                        mb: 1
                    }}
                >
                    <Typography variant="body1">
                        {data.value}
                    </Typography>
                </ListItem>
            ))}
        </List>
    </Card>
));

export default Eligibility; 