import React from 'react';
import { Box, Paper, Stack, Typography, Container } from '@mui/material';
import Iconify from '../../components/Iconify';

const TournamentAbout = ({ data, mdUp }) => {
  const infoData = data?.props?.find(p => p.key === 'list')?.data || [];
  
  const regularInfo = infoData.filter(item => item.type !== 'location');
  const locationInfo = infoData.find(item => item.type === 'location');

  return (
    <Paper variant="elevation" elevation={12}>
      <Container maxWidth="lg">
        <Box py={mdUp ? 3 : 2} px={mdUp ? 4 : 2}>
          <Stack spacing={3}>
            <Box display="flex" flexDirection={mdUp ? 'row' : 'column'} gap={2}>
              {regularInfo.map((item, index) => (
                <Stack
                  key={index}
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  sx={{
                    p: 2,
                    borderRadius: 1,
                    bgcolor: 'background.neutral',
                    flex: 1,
                    minWidth: { md: 200 }
                  }}
                >
                  <Iconify 
                    icon={item.icon} 
                    sx={{
                      width: 24,
                      height: 20,
                      flexShrink: 0,
                    }}
                  />
                  <Typography variant="body2">
                    {item.label}
                  </Typography>
                </Stack>
              ))}
            </Box>

            {locationInfo && (
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                sx={{
                  p: 2,
                  borderRadius: 1,
                  bgcolor: 'background.neutral',
                }}
              >
                <Iconify icon={locationInfo.icon || "logos:google-maps"} width={24} />
                <Box>
                  <Typography variant="subtitle2">
                    {locationInfo.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {locationInfo.address}
                  </Typography>
                </Box>
              </Stack>
            )}
          </Stack>
        </Box>
      </Container>
    </Paper>
  );
};

export default TournamentAbout; 