import React from 'react';
import { Box, Typography, Grid, Stack, useTheme } from '@mui/material';
import { Icon } from '@iconify/react';
import { alpha } from '@mui/material/styles';

const TournamentIconList = ({ data }) => {
  const theme = useTheme();
  const list = data?.props?.find(prop => prop.key === 'list')?.data || [];
  const title = data?.props?.find(prop => prop.key === 'title')?.data || '';

  // Color mapping for dark/light modes
  const getColors = (color) => {
    const themeColor = theme.palette[color.replace('#', '')] || color;
    return {
      color: themeColor,
      backgroundColor: alpha(themeColor, theme.palette.mode === 'dark' ? 0.2 : 0.1)
    };
  };

  return (
    <Box sx={{ py: 4 }}>
      {title && (
        <Typography 
          variant="h4" 
          textAlign="center" 
          gutterBottom 
          sx={{ mb: 4 }}
        >
          {title}
        </Typography>
      )}
      
      <Grid container spacing={3}>
        {list.map((item, index) => {
          const { color, backgroundColor } = getColors(item.color);
          
          return (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                sx={{
                  p: 2,
                  borderRadius: 1,
                  backgroundColor: theme.palette.mode === 'dark' 
                    ? alpha(color, 0.1)
                    : backgroundColor,
                  border: `1px solid ${alpha(color, 0.1)}`,
                  '&:hover': {
                    boxShadow: `0 0 20px 0 ${alpha(color, 0.15)}`,
                    transform: 'translateY(-4px)',
                    transition: theme.transitions.create(['box-shadow', 'transform'], {
                      duration: theme.transitions.duration.shorter,
                    }),
                    backgroundColor: theme.palette.mode === 'dark' 
                      ? alpha(color, 0.15)
                      : alpha(color, 0.15),
                  },
                }}
              >
                <Box
                  sx={{
                    width: 48,
                    height: 48,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: 1,
                    backgroundColor: alpha(color, theme.palette.mode === 'dark' ? 0.2 : 0.1),
                  }}
                >
                  <Icon 
                    icon={item.icon} 
                    width={32} 
                    height={32} 
                    style={{ 
                      color: theme.palette.mode === 'dark' 
                        ? alpha(color, 0.8)
                        : color 
                    }}
                  />
                </Box>

                <Typography 
                  variant="body1"
                  sx={{
                    color: theme.palette.text.primary,
                    flexGrow: 1,
                  }}
                >
                  {item.label}
                </Typography>
              </Stack>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default TournamentIconList; 