import React, { forwardRef } from 'react';
import { Grid, Stack, Box } from '@mui/material';
import TournamentFaq from './TournamentFaq';
import TournamentRules from './TournamnetRules';
import TournamentContact from './TournamentContact';
import TournamentHero from './TournamentHero';
import TournamentSubEvents from './TournamentSubEvents';
import TournamentNotices from './tournamentNotice';
import TournamentTimeline from './TournamentTimeline';
import TournamentVenue from './TournamentVenue';
import TournamentOrg from './TournamentOrg';
import TournamentDescription from './TournamentDescription';
import TournamentAbout from './TournamentAbout';
import TournamentIconList from './TournamentIconList';

const ComponentMap = {
  comp_hero: TournamentHero,
  comp_notice: TournamentNotices,
  comp_timeline: TournamentTimeline,
  comp_sub_event_card: TournamentSubEvents,
  comp_org_card: TournamentOrg,
  comp_club_card: TournamentVenue,
  comp_contact_list: TournamentContact,
  comp_rules: TournamentRules,
  comp_faq: TournamentFaq,
  comp_tournament_desc_editor: TournamentDescription,
  comp_event_about: TournamentAbout,
  comp_icon_list: TournamentIconList,
};

const RenderComponent = forwardRef(({ templateSectionId, eventData, config, registrationData, mdUp, componentId }, ref) => {
  const Component = ComponentMap[componentId];
  if (!Component) return null;

  const componentData = eventData?.landing_page_sections?.find(
    section => section.componentId === componentId 
  );
  if (!componentData) return null;

  return (
    <Box sx={{ mb: { xs: 2, md: 3 } }}>
      <Component
        data={componentData}
        config={config}
        registrationData={registrationData}
        mdUp={mdUp}
        ref={ref}
      />
    </Box>
  );
});

const TournamentComponentFactory = forwardRef(({ config, registrationData, mdUp, eventData }, ref) => {
  const layout = eventData?.layout || eventData?.template?.layout;
  if (!layout) return null;

  return (
    <Stack spacing={0}>
      {layout.map((row, rowIndex) => (
        <Grid
          container
          key={rowIndex}
          spacing={2}
          alignItems={row.props?.align || 'flex-start'}
          justifyContent={row.props?.justify || 'flex-start'}
        >
          {row.columns.map((column, colIndex) => (
            <Grid
              item
              key={`${rowIndex}-${colIndex}`}
              xs={column.span.xs}
              md={column.span.md}
            >
              {column.type === 'column' ? (
                <Grid container spacing={2}>
                  {column.columns.map((nestedCol, nestedIndex) => (
                    <Grid
                      item
                      key={`${rowIndex}-${colIndex}-${nestedIndex}`}
                      xs={nestedCol.span.xs}
                      md={nestedCol.span.md}
                    >
                      <RenderComponent
                        templateSectionId={nestedCol.templateSectionId}
                        eventData={eventData}
                        config={config}
                        registrationData={registrationData}
                        componentId={eventData?.landing_page_sections?.find(
                          section => section.templateSectionId === nestedCol.templateSectionId
                        )?.componentId}
                        mdUp={mdUp}
                        ref={ref}
                      />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <RenderComponent
                  templateSectionId={column.templateSectionId}
                  eventData={eventData}
                  config={config}
                  registrationData={registrationData}
                  componentId={eventData?.landing_page_sections?.find(
                    section => section.templateSectionId === column.templateSectionId
                  )?.componentId}
                  mdUp={mdUp}
                  ref={ref}
                />
              )}
            </Grid>
          ))}
        </Grid>
      ))}
    </Stack>
  );
});

export default TournamentComponentFactory; 