import React from 'react';
import { Box, Typography, Stack, Paper, IconButton, Tooltip } from '@mui/material';
import Iconify from '../../components/Iconify';
import useResponsive from '../../hooks/useResponsive';

const TournamentContact = ({ data }) => {
  const mdUp = useResponsive('up', 'md');
  const contacts = data?.props?.find(p => p.key === 'list')?.data;
  const title = data?.props?.find(p => p.key === 'title')?.data;

  const handleCopyNumber = (number) => {
    navigator.clipboard.writeText(number);
  };

  return (
    <Paper variant="elevation" elevation={12}>
      <Box p={2}>
        <Typography 
          variant="subtitle2"
          color="text.secondary"
          align="center"
        >
          {title}
        </Typography>
        <Stack spacing={2} mt={1}>
          {contacts?.map((contact, index) => (
            <Stack key={index} spacing={1} alignItems="center">
              <Typography 
                variant={mdUp ? "h6" : "subtitle1"} 
                sx={{ fontWeight: '600' }}
                align="center"
              >
                {contact.name}
              </Typography>
              <Stack spacing={1} alignItems="center">
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Tooltip title="Send email">
                    <IconButton 
                      href={`mailto:${contact.email}`}
                      size="small"
                      color="primary"
                    >
                      <Iconify icon={contact.iconEmail} width={16} height={16} />
                    </IconButton>
                  </Tooltip>
                  <Typography variant="body2" color="text.secondary">
                    {contact.email}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Tooltip title="Call">
                    <IconButton 
                      href={`tel:+91${contact.phone}`}
                      size="small"
                      color="primary"
                    >
                      <Iconify icon={contact.iconPhone} width={16} height={16} />
                    </IconButton>
                  </Tooltip>
                  <Typography variant="body2" color="text.secondary">
                    +91 {contact.phone}
                  </Typography>
                  <Tooltip title="Copy number">
                    <IconButton
                      onClick={() => handleCopyNumber(`+91${contact.phone}`)}
                      size="small"
                      color="primary"
                    >
                      <Iconify icon="solar:copy-linear" width={16} height={16} />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Box>
    </Paper>
  );
};

export default TournamentContact;
