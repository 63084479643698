import { createSlice } from '@reduxjs/toolkit';
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';

import { fetchProfileData, createProfileForOrg, makePayment, addSubscription } from '../actions/userActions';
import { login, otpValidate, signup } from './registration';

const resetForm = (state) => {
    state.activeStep = 0;
    state.loginFormOpen = false;
    state.timer = 30;
    state.isResendEnabled = false;
    state.mobileWithCode = '';
    state.mobile = '';
    state.dialCode = '';
    state.otp = '';
    state.firstName = '';
    state.lastName = '';
    state.gender = '';
    state.dateOfBirth = null;
    state.haveReferralCode = false;
    state.referralCode = '';
}
const initialState = {
    user: {},
    players: [],
    currentOrgProfiles: [],
    currentPlayer: {},
    loader: false,
    profileSelectedBySelf: false,
    loggedOut: false,
    referenceNumber: '',
    createProfileData: {},
    activeStep: 0,
    mobileWithCode: '',
    mobile: '',
    dialCode: '',
    otp: '',
    firstName: '',
    lastName: '',
    gender: '',
    dateOfBirth: null,
    isLoading: false,
    isResendEnabled: false,
    timer: 30,
    haveReferralCode: false,
    referralCode: '',
    profileName: '',
    stepTitles: [
        'Login',
        'Verify Mobile',
        'Sign Up',
        'Continue as',
        'Terms and Conditions',
        'Active Subscriptions',
        'Add New Profile',
    ],
    termsAndConditions: [],
    error: null,
    loginFormOpen: false,
    cartClubId: '',
    shouldNavigateToCart: false,
    filterType: 'subscription',
    filterId: '',
};

const updateCurrentOrgProfiles = (state, action, { hasUserInteracted = true, selectLatestProfile = false } = {}) => {
    const players = action?.payload?.data?.players || state.players;
    const user = action?.payload?.data || state.user;
    let filteredProfiles = [];
    if (state.filterType === 'subscription') {
        filteredProfiles = players.filter(player => player?.organizationId === state.filterId);
    } else if (state.filterType === 'event') {
        filteredProfiles = players.filter(player => player?.clubId === state.filterId);
    }

    state.currentOrgProfiles = filteredProfiles;

    if (!user?.id) {
        state.activeStep = 0;
        state.currentPlayer = {};
        state.profileSelectedBySelf = false;
        return;
    }

    if (filteredProfiles.length === 0) {
        state.currentPlayer = {};
        state.profileSelectedBySelf = false;
        state.activeStep = 4;
        return;
    }

    if (filteredProfiles.length === 1) {
        const profile = filteredProfiles[0];
        state.currentPlayer = profile;
        state.profileSelectedBySelf = true;
        
        if (profile?.subscriptions?.length === 0) {
            state.loginFormOpen = false;
            state.shouldNavigateToCart = hasUserInteracted;
        } else {
            state.activeStep = 5;
        }
        return;
    }

    if (selectLatestProfile) {
        const latestProfile = filteredProfiles.reduce((latest, current) => {
            if (!latest) return current;
            return new Date(current.createdAt) > new Date(latest.createdAt) ? current : latest;
        }, null);
        
        state.currentPlayer = latestProfile;
        state.profileSelectedBySelf = true;
        state.loginFormOpen = false;
        
        if (latestProfile?.subscriptions?.length === 0) {
            state.shouldNavigateToCart = hasUserInteracted;
        }
    } else {
        state.currentPlayer = {};
        state.profileSelectedBySelf = false;
        state.activeStep = 3;
    }
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setReferenceNumber: (state, action) => {
            state.referenceNumber = action.payload;
        },
        userLogout: (state) => {
            localStorage.clear();
            Object.assign(state, initialState);
        },
        refreshCurrentOrgProfiles: (state) => {
            updateCurrentOrgProfiles(state);
        },
        selectProfile: (state, action) => {
            const { profileId, profileSelectedBySelf = true } = action.payload;
            const pp = state.currentOrgProfiles.find((player) => player?.id === profileId) || {};
            state.currentPlayer = pp;
            if (pp?.subscriptions?.length > 0) {
                state.activeStep = 5;
            } else {
                state.loginFormOpen = false;
                state.shouldNavigateToCart = true;
            }
            state.profileSelectedBySelf = profileSelectedBySelf;
        },
        setActiveStep: (state, action) => {
            state.activeStep = action.payload;
        },
        updateField: (state, action) => {
            const { field, value } = action.payload;
            if (field === 'mobileWithCode') {
                if (isValidPhoneNumber(value || '')) {
                    const phoneNumber = parsePhoneNumber(value);
                    state.mobile = phoneNumber.nationalNumber;
                    state.dialCode = phoneNumber.countryCallingCode;
                }
                state.mobileWithCode = value;
            } else {
                state[field] = value;
            }
        },
        toggleHaveReferralCode: (state) => {
            state.haveReferralCode = !state.haveReferralCode;
        },
        resetTimer: (state) => {
            state.timer = 30;
            state.isResendEnabled = false;
        },
        decrementTimer: (state) => {
            state.timer = Math.max(state.timer - 1, 0);
            state.isResendEnabled = state.timer === 0;
        },
        setLoginFormOpen: (state, action) => {
            state.loginFormOpen = action.payload;
            if (action.payload) {
                if (state.user?.id) {
                    if (state.currentOrgProfiles.length > 0) {
                        state.activeStep = 3;
                    } else {
                        state.activeStep = 4;
                    }
                } else {
                    state.activeStep = 0;
                }
            } else {
                resetForm(state);
            }
        },
        updateTermsAndConditions: (state, action) => {
            state.termsAndConditions = action.payload;
        },
        updateProfiles: (state, action) => {
            state.currentOrgProfiles = action.payload.currentOrgProfiles || [];
            state.currentPlayer = action.payload.currentPlayer || {};
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        startTimer: (state) => {
            state.timer = 30;
            state.isResendEnabled = false;
        },
        continueWithPayment: (state) => {
            state.loginFormOpen = false;
            state.shouldNavigateToCart = true;
        },
        resetShouldNavigateToCart: (state) => {
            state.shouldNavigateToCart = false;
        },
        setRegistrationFlowFilter: (state, action) => {
            const { filterType, filterId } = action.payload;
            state.filterType = filterType;
            state.filterId = filterId;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(login.fulfilled, (state) => {
                state.isLoading = false;
                state.activeStep = 1;
            })
            .addCase(login.rejected, (state) => {
                state.isLoading = false;
            });
        builder
            .addCase(signup.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(signup.fulfilled, (state) => {
                state.isLoading = false;
                state.activeStep = 1;
            })
            .addCase(signup.rejected, (state) => {
                state.isLoading = false;
            });
        builder
            .addCase(otpValidate.pending, (state) => {
                state.loader = true;
            })
            .addCase(otpValidate.fulfilled, (state, action) => {
                const data = action.payload?.data || {};
                state.user = data;
                state.players = data.players || [];
                state.loader = false;
                updateCurrentOrgProfiles(state, action);
            })
            .addCase(otpValidate.rejected, (state) => {
                state.loader = false;
            });

        builder
            .addCase(createProfileForOrg.pending, (state) => {
                state.loader = true;
            })
            .addCase(createProfileForOrg.fulfilled, (state, action) => {
                state.loader = false;
                const data = action.payload?.data || {};
                state.createProfileData = data;
                state.players = data.players || [];
                updateCurrentOrgProfiles(state, action, { selectLatestProfile: true });
                state.loginFormOpen = false;
                state.shouldNavigateToCart = true;
            })
            .addCase(createProfileForOrg.rejected, (state, action) => {
                state.loader = false;
                if (action.payload?.error?.code === 401) {
                    localStorage.removeItem('token');
                    Object.assign(state, initialState);
                }
            });
        builder
            .addCase(makePayment.pending, (state) => {
                state.registrationLoader = true;
            })
            .addCase(makePayment.fulfilled, (state, action) => {
                state.registrationLoader = false;
                state.paymentData = action.payload?.data || {};
            })
            .addCase(makePayment.rejected, (state) => {
                state.registrationLoader = false;
            });
        builder
            .addCase(addSubscription.pending, (state) => {
                state.registrationLoader = true;
                state.addSubscriptionLoader = true;
            })
            .addCase(addSubscription.fulfilled, (state, action) => {
                state.registrationLoader = false;
                state.addSubscriptionLoader = false;
                state.registeredData = action.payload || {};
            })
            .addCase(addSubscription.rejected, (state) => {
                state.registrationLoader = false;
                state.addSubscriptionLoader = false;
                state.leadGenerated = false;
            });
        builder.addCase(fetchProfileData.pending, (state) => {
            state.loader = true;
        });
        builder.addCase(fetchProfileData.fulfilled, (state, action) => {
            state.loader = false;
            const data = action?.payload?.data
            state.user = data
            state.players = data?.players || []
            updateCurrentOrgProfiles(state, action, {hasUserInteracted: false, selectLatestProfile: true})

        });
        builder.addCase(fetchProfileData.rejected, (state, data) => {
            state.loader = false;
            if (data?.payload?.error?.code === 401) {
                localStorage.removeItem('token')
                return { ...initialState }
            }
        });
    },
});

export const {
    setReferenceNumber,
    userLogout,
    refreshCurrentOrgProfiles,
    selectProfile,
    setActiveStep,
    updateField,
    toggleHaveReferralCode,
    resetTimer,
    decrementTimer,
    setLoginFormOpen,
    updateTermsAndConditions,
    updateProfiles,
    setError,
    startTimer,
    continueWithPayment,
    resetShouldNavigateToCart,
    setRegistrationFlowFilter,
} = userSlice.actions;

export default userSlice.reducer;
