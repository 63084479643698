import { Card, CardContent, Stack, Typography, alpha, Divider,  } from '@mui/material';
import moment from 'moment/moment';
import MyAvatar from '../../../components/MyAvatar';
import Iconify from '../../../components/Iconify';

const PartnerCard = ({ profile, selectedProfile, onSelect }) => {
  const profileId = profile.id;

  return (
    <Card
      sx={{
        mb: 1.5,
        width: '100%',
        maxWidth: { xs: '100%', sm: '320px' },
        position: 'relative',
        border: (theme) => 
          selectedProfile?.id === profileId 
            ? `2px solid ${theme.palette.primary.main}` 
            : `1px solid ${theme.palette.divider}`,
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          transform: 'translateY(-2px)',
          boxShadow: (theme) => theme.shadows[8],
        },
        backgroundColor: (theme) =>
          selectedProfile?.id === profileId
            ? alpha(theme.palette.primary.lighter, 0.1)
            : theme.palette.background.paper,
      }}
      onClick={() => onSelect(profile)}
      style={{ cursor: 'pointer' }}
    >
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: 2,
          p: '12px !important',
          height: { xs: '70px', sm: '80px' },
        }}
      >
        <MyAvatar
          sx={{
            width: { xs: 40, sm: 48 },
            height: { xs: 40, sm: 48 },
            boxShadow: (theme) => theme.customShadows.z8,
          }}
          user={profile}
        />

        <Stack spacing={0.5} sx={{ flex: 1, minWidth: 0 }}>
          <Typography 
            variant="subtitle2" 
            sx={{ 
              fontWeight: 600, 
              color: 'text.primary',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}
          >
            {profile.firstName} {profile.lastName}
          </Typography>
          {profile.gender && (
            <Typography 
              variant="caption" 
              sx={{ 
                color: 'text.secondary',
                display: 'flex',
                alignItems: 'center',
                gap: 0.5
              }}
            >
              {profile.gender}
            </Typography>
          )}
          {profile.dob && (
            <Typography 
              variant="caption" 
              sx={{ 
                color: 'text.secondary',
                display: 'flex',
                alignItems: 'center',
                gap: 0.5
              }}
            >
              {moment(profile.dob).format('DD MMM YYYY')}
            </Typography>
          )}
        </Stack>

        {selectedProfile?.id === profileId && (
          <Iconify
            sx={{
              position: 'absolute',
              top: '50%',
              right: { xs: 8, sm: 12 },
              transform: 'translateY(-50%)',
              color: 'primary.main',
              width: { xs: 20, sm: 24 },
              height: { xs: 20, sm: 24 },
              filter: (theme) => `drop-shadow(0 2px 4px ${alpha(theme.palette.common.black, 0.1)})`,
            }}
            icon={'eva:checkmark-circle-2-fill'}
          />
        )}
      </CardContent>
    </Card>
  );
};

const PartnerSection = ({ title, profiles, selectedProfile, onProfileSelect }) => {
  if (!profiles?.length) return null;

  return (
    <>
      <Stack spacing={1} sx={{ mb: 1.5, mt: 1 }}>
        <Divider>
          <Typography variant="subtitle2" sx={{ color: 'text.secondary', px: 1 }}>
            {title}
          </Typography>
        </Divider>
      </Stack>
      <Stack spacing={1} sx={{ px: { xs: 1, sm: 0 } }}>
        {profiles.map((profile) => (
          <PartnerCard
            key={profile.id}
            profile={profile}
            selectedProfile={selectedProfile}
            onSelect={onProfileSelect}
          />
        ))}
      </Stack>
    </>
  );
};

export { PartnerCard, PartnerSection };
