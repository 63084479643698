import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Avatar,
  Stack,
  Box,
  Divider,
} from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';

const UserCard = ({ user }) => {
  const theme = useTheme();

  if (!user) return null;

  // Get user initials for Avatar if no image is available
  const getUserInitials = () => {
    return `${user.firstName?.[0] || ''}${user.lastName?.[0] || ''}`.toUpperCase();
  };

  console.log(user, "user");
  return (
    <Card
      sx={{
        mx: 2,
        border: `1px solid ${theme.palette.divider}`,
        boxShadow: `0 4px 8px ${alpha(theme.palette.common.black, 0.08)}`,
        backgroundColor: 'background.paper',
        position: 'relative',
        zIndex: 1,
        transition: 'transform 0.2s ease-in-out, z-index 0.2s',
        '&:hover': {
          transform: 'translateY(-2px)',
          zIndex: 2,
        },
      }}
    >
      <CardContent sx={{ p: theme.spacing(2) }}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Avatar
            alt={`${user.firstName} ${user.lastName}`}
            sx={{
              width: theme.spacing(6),
              height: theme.spacing(6),
              border: `2px solid ${theme.palette.background.paper}`,
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.getContrastText(theme.palette.primary.main),
            }}
          >
            {getUserInitials()}
          </Avatar>
          <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
              {user.firstName} {user.lastName}
            </Typography>
          </Box>
        </Stack>

        <Divider sx={{ my: theme.spacing(1.5) }} />

        <Box
          sx={{
            display: 'grid',
            gap: theme.spacing(1),
            gridTemplateColumns: 'repeat(2, 1fr)',
          }}
        >
          <Box>
            <Typography variant="caption" color="text.secondary">
              Date of Birth
            </Typography>
            <Typography variant="body2">{user.dob}</Typography>
          </Box>

          <Box>
            <Typography variant="caption" color="text.secondary">
              Gender
            </Typography>
            <Typography variant="body2">{user.gender}</Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default UserCard;