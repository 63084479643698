import { useState, useMemo, useCallback, forwardRef, useImperativeHandle } from 'react';
import { Box, Typography, Card, CardContent, Button, Stack, List, Chip } from '@mui/material';
import { useSelector } from 'react-redux';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import GroupsIcon from '@mui/icons-material/Groups';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { fCurrency } from '../../../utils/formatNumber';
import PartnerDialog from './PartnerDialog';
import LoadingScreen from '../../../components/LoadingScreen';
import useResponsive from '../../../hooks/useResponsive';
import Scrollbar from '../../../components/Scrollbar';
import useRegistration from './useRegistration';
import EventFilters from './EventFilters';

const TournamentRegister = forwardRef((_, ref) => {
  const mdUp = useResponsive('up', 'md');
  const { eventDetailsData, eventDetailsLoader } = useSelector((state) => state.eventDetails);
  const { tournamentData } = useSelector((state) => state.eventRegistration);
  const [openPartnerDialog, setOpenPartnerDialog] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const { 
    updateTournamentData, 
    nextStep, 
    handleEventSelection, 
    submitPartnerDetails 
  } = useRegistration();

  const [filters, setFilters] = useState({
    ageGroup: 'all',
    gender: 'all',
    type: 'all'
  });

  const handleFilterChange = (type, value) => {
    setFilters(prev => ({
      ...prev,
      [type]: value
    }));
  };

  const filteredEvents = useMemo(() => {
    if (!eventDetailsData?.sub_events) return [];

    return eventDetailsData.sub_events.filter(event => {
      const matchesAge = filters.ageGroup === 'all' || event.ageGroup === filters.ageGroup;
      const matchesGender = filters.gender === 'all' || event.gender === filters.gender;
      const matchesType = filters.type === 'all' || event.type === filters.type;
      return matchesAge && matchesGender && matchesType;
    });
  }, [eventDetailsData?.sub_events, filters]);

  useImperativeHandle(ref, () => ({
    onNextStep: () => {
      if (tournamentData.selectedEvents?.length === 0) {
        enqueueSnackbar('Please select at least one event', {
          variant: 'error',
          autoHideDuration: 3000,
        });
        return;
      }

      updateTournamentData(tournamentData);
      nextStep();
    }
  }));

  const handleSelect = useCallback(
    (event) => {
      const result = handleEventSelection(event, eventDetailsData, tournamentData);
      
      if (!result.success) {
        enqueueSnackbar(result.error, {
          variant: 'error',
          autoHideDuration: 3000,
        });
        return;
      }

      if (result.requiresPartner) {
        setCurrentEvent(event);
        setOpenPartnerDialog(true);
      }
    },
    [handleEventSelection, eventDetailsData, tournamentData, enqueueSnackbar]
  );

  const handlePartnerSubmit = (data) => {
    if (currentEvent) {
      submitPartnerDetails(currentEvent, data, tournamentData);
      setOpenPartnerDialog(false);
      setCurrentEvent(null);
    }
  };

  const handleAddPartner = useCallback((event) => {
    setCurrentEvent(event);
    setOpenPartnerDialog(true);
  }, []);

  if (eventDetailsLoader === 'loading') {
    return <LoadingScreen />;
  }

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column',
      height: '100%',
    }}>
      <EventFilters 
            filters={filters}
            onFilterChange={handleFilterChange}
          />
      <Scrollbar
        sx={{ height: 1 }}
        slotProps={{
          content: { height: 1, display: 'flex', alignItems: 'center' },
        }}
      >
        <Box sx={{ width: '100%', p: 2 }}>
          

          <List sx={{ width: '100%' }}>
            {filteredEvents.map((event) => (
              <Card key={event.id} sx={{ mb: 2 }}>
                <CardContent>
                  <Stack spacing={0}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <GroupsIcon color="action" sx={{ fontSize: '1.2rem' }} />
                      <Typography variant="h6">{event.name}</Typography>
                      {event.isTeamEvent ? (
                        <Chip
                          label="Doubles"
                          size="small"
                          sx={{
                            ml: 1,
                          }} />
                      ) : null}
                    </Stack>

                    {event.isTeamEvent
                      ? tournamentData.selectedEvents?.includes(event.id) && (
                        <Stack spacing={0} sx={{ mt: 0 }}>
                          <Typography variant="body2" color="text.secondary" sx={{ mb: 0 }}>
                            Partner
                          </Typography>
                          <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Typography variant="h6">{tournamentData.partnerDetails[event.id]?.name || tournamentData.partnerDetails[event.id]?.firstName || 'Add your partner'}</Typography>
                            <Button
                              startIcon={tournamentData.partnerDetails[event.id] ? <EditIcon /> : <PersonAddIcon />}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleAddPartner(event);
                              } }
                            >
                              {tournamentData.partnerDetails[event.id] ? 'Edit Partner' : 'Add Partner'}
                            </Button>
                          </Stack>
                        </Stack>
                      )
                      : null}

                    <>
                      <Typography variant="subtitle2" color="text.secondary">
                        Registration Fee
                      </Typography>
                      <Typography variant="h6">{fCurrency(event.priceConfig?.price)}</Typography>
                    </>

                    <Button
                      startIcon={tournamentData.selectedEvents?.includes(event.id) ? <CheckCircleOutlineIcon /> : null}
                      variant={tournamentData.selectedEvents?.includes(event.id) ? 'contained' : 'outlined'}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSelect(event);
                      } }
                      fullWidth
                      sx={{ mt: 1 }}
                    >
                      {tournamentData.selectedEvents?.includes(event.id) ? 'Selected' : 'Select'}
                    </Button>
                  </Stack>
                </CardContent>
              </Card>
            ))}
          </List>
        </Box>
      </Scrollbar>
      
      <PartnerDialog
        open={openPartnerDialog}
        onClose={() => {
          setOpenPartnerDialog(false);
        } }
        onSubmit={handlePartnerSubmit}
        currentEvent={currentEvent}
         />
    </Box>
  );
});

export default TournamentRegister;
