import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios'
import { API_STATUS, KRIDA_API } from '../../config';

const initialState = {
    eventListLoader: 'idle',
    eventDetailsLoader: 'idle',
    eventListData: [],
    eventDetailsData: {},
    error: {},
    pageCount: 0,
    similarEventDataLoader: API_STATUS.IDLE,
    similarEventData: [],
    limit: 20,
    registerData: {}
}
const eventSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {
        resetEventList: (state) => {
            state.error = {}
            state.eventListLoader = 'idle'
        },
        resetEventDetails: (state) => {
            state.error = {}
            state.eventDetailsLoader = 'idle'
        },
        resetRegisterData: (state) => {
            state.registerData = {}
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchEventList.pending, (state) => {
            state.eventListLoader = 'loading'
        });
        builder.addCase(fetchEventList.fulfilled, (state, action) => {
            state.eventListLoader = 'succeeded'
            state.eventListData = action?.payload?.data?.rows;
            state.pageCount = Math.ceil((action?.payload?.data?.count || 0) / (action?.payload?.data?.limit || 20))
        });
        builder.addCase(fetchEventList.rejected, (state) => {
            state.eventListLoader = 'failed'
        });
        builder.addCase(fetchSimilarEventList.pending, (state) => {
            state.similarEventDataLoader = 'loading'
        });
        builder.addCase(fetchSimilarEventList.fulfilled, (state, action) => {
            state.similarEventDataLoader = 'succeeded'
            state.similarEventData = action?.payload?.data?.rows;
        });
        builder.addCase(fetchSimilarEventList.rejected, (state) => {
            state.similarEventDataLoader = 'failed'
        });
        builder.addCase(fetchEventDetails.pending, (state) => {
            state.eventDetailsLoader = 'loading'
            state.eventDetailsData = {}
        });
        builder.addCase(fetchEventDetails.fulfilled, (state, action) => {
            state.eventDetailsLoader = 'succeeded'
            state.eventDetailsData = action?.payload?.data
        });
        builder.addCase(fetchEventDetails.rejected, (state) => {
            state.eventDetailsLoader = 'failed'
        });
        builder.addCase(fetchEventBySlug.fulfilled, (state, action) => {
            state.eventDetailsLoader = 'succeeded'
            state.eventDetailsData = action?.payload?.data
        });
        builder.addCase(fetchEventBySlug.rejected, (state) => {
            state.eventDetailsLoader = 'failed'
        });
        builder.addCase(getRegisterData.fulfilled, (state, action) => {
            state.registerData = action?.payload?.data
        });
    }
})

export const fetchEventList = createAsyncThunk('eventLists', async (data, thunkApi) => {
    try {
        const { data: responseData } = await axios.post(`${KRIDA_API}kl/event`, { ...data },
            { headers: { 'content-type': 'application/json' } })
        return responseData
    } catch (e) {
        const error = e?.error
        console.log(error);
        return thunkApi.rejectWithValue(error)
    }
});

export const fetchEventDetails = createAsyncThunk('eventDetails', async (payload, thunkApi) => {
    const { eventId, clubId } = payload;

    try {
        const requestBody = {
            "clubId": clubId ? clubId.toUpperCase() : clubId,
            "includeEventDetails": true,
            "includeEventAggregate": true
        }
        const { data: responseData } = await axios.post(`${KRIDA_API}kl/event/${eventId}`, requestBody,
            { headers: { 'content-type': 'application/json' } })
        return responseData
    } catch (e) {
        const error = e?.error
        console.log(error);
        return thunkApi.rejectWithValue(error)
    }
});

export const fetchEventBySlug = createAsyncThunk('fetchEventBySlug', async (slug, thunkApi) => {
    try {
        const { data: responseData } = await axios.get(`${KRIDA_API}event/pages/by-slug/${slug}`, { headers: { 'content-type': 'application/json' } })
        return responseData
    } catch (e) {
        const error = e?.error
        console.log(error);
        return thunkApi.rejectWithValue(error)
    }
});

export const fetchSimilarEventList = createAsyncThunk('fetchSimilarEventList', async (data, thunkApi) => {
    try {
        const { data: responseData } = await axios.post(`${KRIDA_API}kl/event`, { orgId: data.orgId },
            { headers: { 'content-type': 'application/json' } })
        return responseData
    } catch (e) {
        const error = e?.error
        console.log(error);
        return thunkApi.rejectWithValue(error)
    }
});

export const getRegisterData = createAsyncThunk('getRegisterData', async (slug, thunkApi) => {
    try {
        const { data: responseData } = await axios.get(
            `${KRIDA_API}event/s/${slug}`, 
            { headers: { 'content-type': 'application/json' } }
        );
        return responseData;
    } catch (e) {
        const error = e?.error
        console.log(error);
        return thunkApi.rejectWithValue(error)
    }
});


export const { resetEventList, resetEventDetails } = eventSlice.actions

export default eventSlice.reducer;