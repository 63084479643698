import { Box, Card, Chip, Container, Divider, Grid, Icon, Link, ListItem, ListItemText, Paper, Stack, Tab, Tabs, Typography, List, ListItemIcon, Button, Fab, Tooltip } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { m } from 'framer-motion';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEventBySlug } from '../../redux/slices/event';
import DynamicAspectRatioImage from '../../components/DynamicAspectRatioImage';
import useResponsive from '../../hooks/useResponsive';
import { fNumber, fDate, fCurrency } from '../../utils/formatNumber';
import TournamentTimeline from './TournamentTimeline';
import Iconify from '../../components/Iconify';
import MainLayout from '../../layouts/main';
import { CustomTabs } from '../../components/custom-tabs';
import { fetchEventDetail, fetchRegistrationStatus } from '../../redux/slices/eventDetails';
import { fetchOrgConfig, resetConfig } from '../../redux/slices/orgConfig';
import convertToBase64 from '../../utils/convertToBase64';
import LoadingScreen from '../../components/LoadingScreen';
import Page404 from '../Page404';
import { getLandingData } from '../../redux/slices/landing';
import { API_STATUS } from '../../config';
import TournamentNotices from './tournamentNotice';
import TournamentRules from './TournamnetRules';
import TournamentContact from './TournamentContact';
import TournamentHero from './TournamentHero';
import TournamentSubEvents from './TournamentSubEvents';
import TournamentFaq from './TournamentFaq';
import TournamentComponentFactory from './TournamentComponentFactory';


const TABS = [
    {
        value: 'overview',
        label: 'Overview'
    },
    {
        value: 'draws',
        label: 'Draws'
    },
    {
        value: 'schedule',
        label: 'Schedule'
    },
    {
        value: 'results',
        label: 'Results'
    },
    {
        value: 'players',
        label: 'Players'
    },
    {
        value: 'matches',
        label: 'Matches'
    },
    {
        value: 'news',
        label: 'News'
    },
    {
        value: 'photos',
        label: 'Photos'
    },
    {
        value: 'videos',
        label: 'Videos'
    },
    {
        value: 'sponsors',
        label: 'Sponsors'
    }
];
const CategoryBox = ({ children }) => (
    <Chip
        label={children}
        color='secondary'

    />
);





const RegistrationCard = ({ registration }) => (
    <Paper
        sx={{
            p: 2,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            bgcolor: 'background.neutral'
        }}
    >
        {/* Event */}
        <Stack spacing={0.5} mb={1}>
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                EVENT
            </Typography>
            <Typography variant="subtitle2" color="text.primary">
                {registration.metadata?.subEventName}
            </Typography>
        </Stack>

        {/* Team */}
        {/* <Stack spacing={0.5} mb={1}>
      <Typography variant="caption" sx={{ color: 'text.secondary' }}>
        TEAM
      </Typography>
      <Typography variant="body2" color="text.primary">
        {registration.team?.name}
      </Typography>
    </Stack> */}

        {/* Players */}
        <Stack spacing={0.5}>
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                PLAYERS
            </Typography>
            <Stack spacing={0.25}>
                {registration.metadata?.players?.map((player, playerIndex) => (
                    <Typography
                        key={playerIndex}
                        variant="body2"
                        color="text.primary"
                    >
                        {player.name}
                    </Typography>
                ))}
            </Stack>
        </Stack>

        {/* Status Chip */}
        <Box sx={{ mt: 'auto', pt: 1.5 }}>
            <Chip
                label={registration.status}
                color={registration.status === 'confirmed' ? 'success' : 'warning'}
                size="small"
                variant="filled"
                sx={{
                    height: 20,
                    textTransform: 'capitalize',
                    '& .MuiChip-label': { px: 1, py: 0.25 }
                }}
            />
        </Box>
    </Paper>
);

const RegistrationStatus = ({ registrationData }) => {
    if (!registrationData?.count) return null;

    return (
        <Box sx={{ mt: 1.5, width: '100%' }}>
            <Grid container spacing={1.5}>
                {registrationData.rows.map((registration, index) => (
                    <Grid item xs={12} md={3} key={registration.id}>
                        <RegistrationCard registration={registration} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

const TournamentDetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { tId } = useParams();
    const { slug } = useParams();
    const [currentTab, setCurrentTab] = useState('overview');

    const { eventDetailsLoader, error, registrationData } = useSelector((state) => state.eventDetails);
    const { eventDetailsData } = useSelector((state) => state.event);
    const { configLoader, config, configError } = useSelector((state) => state.orgConfig);
    const [isInvalid, setIsInvalid] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);
    const contentRef = useRef(null);
    const mdUp = useResponsive('up', 'md');
    const { registrationLoader, registrationError } = useSelector((state) => state.registration);
    const { currentPlayer } = useSelector((state) => state.user);



    // Initialize data
    useEffect(() => {
        const fetchData = async () => {
            try {
                await dispatch(fetchEventBySlug(slug)).unwrap();
            } catch (error) {
                console.error('Failed to fetch data:', error);
                setIsInvalid(true);
            }
        };

        fetchData();
    }, [dispatch, tId, currentPlayer?.id, slug]);

    useEffect(() => {
        const fetchRegistrationData = () => {
            if (currentPlayer?.id) {
                dispatch(fetchRegistrationStatus({
                    playerId: currentPlayer.id,
                    eventId: tId
                }));
            }
        };

        fetchRegistrationData();
    }, [currentPlayer?.id, dispatch, tId]);


    useEffect(() => {
        const fetchConfig = () => {
            if (eventDetailsData?.id) {

                const convertedBase64Code = convertToBase64({
                    entity: 'event',
                    entityId: eventDetailsData.id
                });

                try {
                    dispatch(fetchOrgConfig({
                        q: convertedBase64Code
                    }));
                } catch (error) {
                    console.error('Config Error:', error);
                }
            }
        };

        fetchConfig();
        return () => {
            dispatch(resetConfig());
        };
    }, [eventDetailsData.id, dispatch, eventDetailsData]);


    const handleChangeTab = useCallback((event, newValue) => {
        setCurrentTab(newValue);
    }, []);

    const renderContent = () => {
        if (eventDetailsLoader === 'loading') {
            return <LoadingScreen />;
        }
        if (isInvalid ||
            (error && eventDetailsLoader === 'failed') ||
            (!eventDetailsData?.id && eventDetailsLoader === 'succeeded')) {
            return <Page404 />;
        }
        const minPrice = Math.min(...eventDetailsData?.sub_events?.map(event => event.priceConfig.price) || [0]);
        const landingPageSections = eventDetailsData?.landing_page_sections || [];

        return (
            <Container maxWidth={"lg"} align="center" sx={{ maxHeight: "100%", minHeight: "540" }}>
                {configLoader ? (
                    <LoadingScreen />
                ) : (
                    <>
                            <TournamentComponentFactory
                                eventData={eventDetailsData}
                                config={config}
                                registrationData={registrationData}
                                mdUp={mdUp}
                            />

                        <Grid container spacing={3}>
                            {registrationData?.count > 0 && (
                                <Grid item xs={12}>
                                    <RegistrationStatus registrationData={registrationData} />
                                </Grid>
                            )}

                            <Grid item xs={12} md={4} mt={2}>
                                {/* <Paper variant="elevation" sx={{ marginTop: 4 }} elevation={12}>
                                </Paper>                                    */}
                            </Grid>
                        </Grid>
                    </>
                )}
            </Container>
        );
    };

    return (
        <MainLayout>
            {renderContent()}
        </MainLayout>
    );
};

export default TournamentDetails;
