import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import useResponsive from '../../hooks/useResponsive';

const TournamentDescription = ({ data }) => {
  const mdUp = useResponsive('up', 'md');
  const description = data?.props?.find(p => p.key === 'description')?.data;
  const title = data?.props?.find(p => p.key === 'title')?.data || 'Description';

  return (
    <Paper variant="elevation" elevation={12}>
      <Box p={3}>
        <Typography 
          variant="subtitle2"
          color="text.secondary"
          sx={{ mb: 1 }}
        >
          {title}
        </Typography>
        <Box>
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </Box>
      </Box>
    </Paper>
  );
};

export default TournamentDescription;
