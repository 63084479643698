import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@mui/material';

import { fetchEventDetails, fetchEventBySlug } from '../../redux/slices/event';
import MainLayout from '../../layouts/main';
import config from './config.json';
import configAdvanced from './configAdvanced.json';
import ComponentFactory from './ComponentFactory';

const Main = () => {
  const dispatch = useDispatch();
  const {  clubId, slug } = useParams();
  const { eventDetailsData } = useSelector(state => state.event);

  const aboutRef = useRef(null);
  const timelineRef = useRef(null);
  const faqRef = useRef(null);
  const moreEventsRef = useRef(null);
  const eligibilityRef = useRef(null);
  const highlightsRef = useRef(null);

  const componentRefs = {
    about: aboutRef,
    timeline: timelineRef,
    faq: faqRef,
    'more events': moreEventsRef,
    eligibility: eligibilityRef,
    highlights: highlightsRef,
  };

  useEffect(() => {
    dispatch(fetchEventBySlug(slug));
  }, [dispatch, slug]);

  const eventId= 'psm-advanced-camp'




  const selectedEvent = clubId === 'psm-ccp' ? config : configAdvanced;
  // const visibleComponents = selectedEvent.componentList.filter((component) => component.isVisible);
  // const visibleComponents = eventDetailsData?.componentList?.filter((component) => component.isVisible);
  console.log(eventDetailsData?.landing_page_sections);
 
  return (
    <MainLayout>
      <Box
        sx={{
          padding: '80px', 
          paddingTop: '0px',
          paddingBottom: '32px', 
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          '@media (max-width: 600px)': {
            marginTop: '0',
            padding: '0 16px', 
          },
        }}
      >
        {/* {visibleComponents.map((component) => (
          <ComponentFactory
            key={component.id}
            type={component.type}
            componentData={component}
            ref={componentRefs[component.type.toLowerCase()]}
            allRefs={componentRefs}
            visibleComponents={visibleComponents}
          />
        ))} */}
     {eventDetailsData?.landing_page_sections?.map((section) => (
          <ComponentFactory
            key={section.id}
            type={section.componentId} 
            componentData={section.props}
            ref={componentRefs[section.componentId?.replace('comp_', '')?.toLowerCase()]}
            allRefs={componentRefs}
            visibleComponents={eventDetailsData?.landing_page_sections}
          />
        ))}
      </Box>
    </MainLayout>
  );
};

export default Main;
