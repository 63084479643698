import { createSlice, createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios'
import { KRIDA_API } from '../../config';

const initialState = {
    stepper: {
        currentStepId: 'basic-details',
        steps: [],
    },
    formData: {},
    tournamentData: {
        selectedEvents: [],
        partnerDetails: {}
    },
    eventId: null,
    slug: null,
    partnerHistory:[],
    partnerSearch: [],
    loading: false,
    error: null,
}

const tournamentRegistrationSlice = createSlice({
    name: 'tournamentRegistration',
    initialState,
    reducers: {
        setCurrentStepById: (state, action) => {
            state.stepper.currentStepId = action.payload;
        },
        updateSteps: (state, action) => {
            state.stepper.steps = action.payload;
        },
        updateFormData: (state, action) => {
            if (action.payload.selectedEvents || action.payload.partnerDetails) {
                state.tournamentData = {
                    ...state.tournamentData,
                    ...action.payload
                };
            } else {
                state.formData = {
                    ...state.formData,
                    ...action.payload,
                };
            }
        },
        resetFormData: (state) => {
            state.formData = {};
            state.stepper.currentStepId = state.stepper.steps[0].id;
        },
        setEventId: (state, action) => {
            state.eventId = action.payload;
        },
        setSlug: (state, action) => {
            state.slug = action.payload;
        },
        resetState: (state) => {
            state.stepper = initialState.stepper;
            state.formData = initialState.formData;
            state.eventId = initialState.eventId;
            state.slug = initialState.slug;
        },
        setTournamentData: (state, action) => {
            state.tournamentData = {
                ...state.tournamentData,
                ...action.payload
            };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(setSteps, (state, action) => {
                state.stepper.steps = action.payload;
            })
            .addCase(searchNumber.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(searchNumber.fulfilled, (state, action) => {
                state.partnerSearch = action.payload;
                state.loading = false;
                state.error = null;
            })
            .addCase(searchNumber.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(getPartnerHistory.fulfilled, (state, action) => {
                state.partnerHistory = action.payload;
                state.loading = false;
                state.error = null;
            })
    },
})


export const searchNumber = createAsyncThunk('searchNumber', async (mobile, thunkApi) => {
    try {
        const token = localStorage.getItem('token');
        const { data: { data: responseData } } = await axios.get(
            `${KRIDA_API}e/r/player/friend/search?mobile=${mobile}`, 
            { 
                headers: { 
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${token}`
                } 
            }
        );
        
        return responseData.rows;
    } catch (e) {
        const error = e?.error;
        console.log(error);
        return thunkApi.rejectWithValue(error);
    }
});


export const getPartnerHistory = createAsyncThunk('getPartnerHistory', async (playerId, thunkApi) => {
    try {
        const token = localStorage.getItem('token');
        const { data: responseData } = await axios.get(
            `${KRIDA_API}e/r/player/friend?playerId=${playerId}`, 
            { 
                headers: { 
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${token}`
                } 
            }
        );
        return responseData.data;
    } catch (e) {
        const error = e?.error;
        console.log(error);
        return thunkApi.rejectWithValue(error);
    }
});

export const { setCurrentStepById, updateSteps, updateFormData, resetFormData, setEventId, resetState, setTournamentData, setSlug } = tournamentRegistrationSlice.actions;

export const setSteps = createAction('tournamentRegistration/setSteps');

export default tournamentRegistrationSlice.reducer;