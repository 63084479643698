import { Suspense, lazy, useEffect, useState } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

// layouts
import DashboardLayout from '../layouts/dashboard';
// guards
import GuestGuard from '../guards/GuestGuard';
import useSettings from '../hooks/useSettings';

import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import Privacy from '../pages/policy/Privacy';
import Refund from '../pages/policy/Refund';
import TnC from '../pages/policy/TnC';
import { fetchProfileData } from '../redux/actions/userActions';
import { login } from '../redux/slices/registration';
import SportEvents from '../components/SportsEvents';
import SasaLanding from '../pages/dashboard/SasaLanding';
import Main from '../pages/dashboard/Main';
import RegisterEvent from '../pages/dashboard/Register/Register';
import LoginEvent from '../pages/dashboard/Register/LoginEvent';
import TournamentDetails from '../pages/tournament/TournamentDetail';
import TournamentRegistration from '../pages/tournament/TournamentRegistration';
import AddEventForRegistration from '../pages/tournament/AddEventRegistrationDialog';
import RegisterCCP from '../pages/dashboard/Register/RegisterCCP';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/')} />}>
      <Component {...props} />
    </Suspense>
  );
};


export default function Router() {
  const token = localStorage.getItem('token');
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { themeLayout } = useSettings();
  const routerLocation = useLocation();

  useEffect(() => {
    const excludedRoutes = ['contact-us', 'about-us', 'policy'];
    const currURL = routerLocation.pathname.trim('/').split('/')
    const searchParams = new URLSearchParams(routerLocation.search);
    if (searchParams.get('utm_source') || searchParams.get('utm_medium') || searchParams.get('utm_campaign') || searchParams.get('utm_content')) {
      const utmParams = {
        utm_source: searchParams.get('utm_source'),
        utm_medium: searchParams.get('utm_medium'),
        utm_campaign: searchParams.get('utm_campaign'),
        utm_term: searchParams.get('utm_term'),
        utm_content: searchParams.get('utm_content'),
      };
      localStorage.setItem('utmParams', JSON.stringify(utmParams));
    }
  }, [routerLocation]);

  useEffect(() => {
    if (token)
      dispatch(fetchProfileData());
  }, []);

  return useRoutes([
    {
      path: "/contact-us",
      element: <Contact />
    },
    {
      path: "/about-us",
      element: <About />
    },
    {
      path: "/welcome/:id",
      element: <Welcome />
    },
    {
      path: "/policy",
      children: [
        { path: 'refund', element: <Refund /> },
        {
          path: 'privacy',
          element: <Privacy />
        },
        {
          path: 'Tnc',
          element: <TnC />
        },
        {
          path: '',
          element: (
            <GuestGuard>
              <GeneralApp />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: 'club', children: [
        {
          path: ':clubId', children: [
            { path: 'event/:eventId', element: <EventDetails /> },
            { path: '', element: <SingleCenter /> },
          ]
        },
        { path: '', element: <GeneralApp /> },
      ]

    },
    {
      path: 'event', children: [
        {
          path: ':eventId', children: [
            { path: ':clubId', element: <EventDetails /> },
            { path: '', element: <RegistrationForm /> },

          ]
        },
        { path: '', element: <SportEvents /> },
      ]
    },
    {
      path: ':orgId', children: [
        { path: 'tnc', element: <TnC orgName={''} address={''} url={''} contactEmail={''} /> },
        { path: 'privacy', element: <Privacy orgName={''} address={''} url={''} contactEmail={''} /> },
        { path: 'refund', element: <Refund orgName={''} address={''} url={''} contactEmail={''} /> },
        { path: 'contact', element: <TnC orgName={''} address={''} url={''} contactEmail={''} /> },
        { path: 'profile', element: <UserAccount /> },
        // { path: 'event/:eventId/:clubId', element: <EventDetails /> },
        { path: 'event/:eventId/:clubId', element: <Main /> },
        // { path: 'event/:eventId/:clubId/register', element: <RegisterCCP /> },
        { path: 'event/:eventId/:clubId/login', element: <LoginEvent /> },
        { path: 'event/:eventId', element: <RegistrationForm /> },
        { path: 'e/s/:slug', element: <Main /> },
        { path: 'event/:slug/register', element: <RegisterCCP /> },
        {path: 't/s/:slug', element: <TournamentDetails/>},
        // { path: 'club/psmvaishnavi', element: <PsmVaishnavi /> },
        { path: 't/:tId', element: <TournamentDetails /> },
        // { path: 't/:tId/register', element: <TournamentRegistration /> },
        { path: 't/:tId/register/a', element: <AddEventForRegistration /> },
        { path: 'club/:clubId', element: <SingleCenter /> },
        { path: 'club/PSMRUSHACE/register/m', element: <AceMemberRegistration /> },
        { path: 'club/:clubId/register', element: <ClubRegularRegistration /> },
        { path: 'invoice/:invoiceId', element: <Invoice />, },
        { path: 'assessment/:playerAssessmentId', element: <PlayerAssessmentsDetails />, },
        { path: 'checkout', element: (<CheckoutV2 />), },
        { path: 'checkout/success/:paramInvoiceId', element: (<AuthGuard> <OrderComplete /> </AuthGuard>), },
        { path: 'registrationConfirmation', element: token ? <RegistrationConfirmation /> : <NotFound />, },
        { path: '', element: <GeneralApp /> },
        { path: '', element: <MultiCenter /> },
      ]
    },

    {
      path: "/*",
      element: (
        <GuestGuard>
          <SportEvents />
        </GuestGuard>
      ),
    },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralPlayerAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralPlayerAnalytics')));
const RegistrationForm = Loadable(lazy(() => import('../pages/dashboard/RegistrationV2')));
const Checkout = Loadable(lazy(() => import('../pages/dashboard/Checkout')));
const CheckoutV2 = Loadable(lazy(() => import('../pages/dashboard/CheckoutV2')));
const Invoice = Loadable(lazy(() => import('../pages/dashboard/Invoice')));
const RegistrationConfirmation = Loadable(lazy(() => import('../pages/dashboard/RegistrationConfirmation')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const About = Loadable(lazy(() => import('../pages/About')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const OrderComplete = Loadable(lazy(() => import('../sections/checkout/CheckoutOrderComplete')));
const MultiCenter = Loadable(lazy(() => import('../pages/dashboard/MultiCenter')));
const SingleCenter = Loadable(lazy(() => import('../pages/dashboard/SingleCenter')));
const ClubRegularRegistration = Loadable(lazy(() => import('../pages/dashboard/ClubRegularRegistration')));
const AceMemberRegistration = Loadable(lazy(() => import('../pages/dashboard/AceMemberRegistration')));
// const PsmVaishnavi = Loadable(lazy(() => import('../pages/dashboard/PsmVaishnavi')));
const EventDetails = Loadable(lazy(() => import('../pages/dashboard/EventDetails')));
const PlayerAssessments = Loadable(lazy(() => import('../pages/dashboard/PlayerAssessments')));
const PlayerAssessmentsDetails = Loadable(lazy(() => import('../pages/dashboard/PlayerAssessmentDetails')));
const Welcome = Loadable(lazy(() => import('../pages/Welcome')));

