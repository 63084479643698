// LoginForm.js

import React, { useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
    Backdrop,
    CircularProgress,
    Typography,
    Button,
    Stack,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation, matchPath } from 'react-router-dom';

import {
    setActiveStep,
    setRegistrationFlowFilter,
} from '../../redux/slices/user';
import { resetCheckOutData } from '../../redux/slices/registration';
import LoginStep from './steps/LoginStep';
import OtpStep from './steps/OtpStep';
import SignUpStep from './steps/SignUpStep';
import ProfileSelectionStep from './steps/ProfileSelectionStep';
import TermsAndConditionsStep from './steps/TermsAndConditionsStep';
import SubscriptionStep from './steps/SubscriptionStep';
import KridaPowered from '../../pages/dashboard/KridaPowered';
import AddProfileStep from './steps/AddProfileStep';
import Iconify from '../Iconify';
import DynamicAspectRatioImage from '../DynamicAspectRatioImage';
import useLoginFormState from './hooks/useLoginFormState';
import { useRouteType } from './hooks/useRouteType';
import useResponsive from '../../hooks/useResponsive';

const LoginForm = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const navigate = useNavigate();
    const { routeType, routeTypeFilterId } = useRouteType();
    const {
        user,
        currentOrgProfiles,
        currentPlayer,
        profileSelectedBySelf,
        activeStep,
        loginFormOpen,
        isLoading,
        stepTitles,
        filterType,
        filterId,
    } = useSelector((state) => state.user);

    const { otpGenerated, referenceCode } = useSelector(
        (state) => state.registration
    );

    const { config } = useSelector((state) => state.orgConfig);
    const { estimates } = useSelector((state) => state.cart);
    const mdUp = useResponsive('up', 'md');
    

    useEffect(() => {
        dispatch(setRegistrationFlowFilter({ filterType: routeType, filterId: routeTypeFilterId }));
    }, [dispatch, routeType, routeTypeFilterId]);

    const {
        actions: {
            startTimer,
            handleProfileSelection,
            setLoginFormOpen,
            updateField,
        },
    } = useLoginFormState();

    const handleClose = () => {
        setLoginFormOpen(false);
    };

    const handleBack = () => {
        switch (activeStep) {
            case 1:
            case 2:
                dispatch(setActiveStep(0));
                updateField('otp', '');
                dispatch(resetCheckOutData());
                break;
            case 5:
                dispatch(setActiveStep(3));
                break;
            default:
                break;
        }
    };

    const renderStepContent = () => {
        switch (activeStep) {
            case 0:
                return <LoginStep />;
            case 1:
                return <OtpStep />;
            case 2:
                return <SignUpStep />;
            case 3:
                return <ProfileSelectionStep />;
            case 4:
                return <TermsAndConditionsStep />;
            case 5:
                return <SubscriptionStep />;
            case 6:
                return <AddProfileStep />;
            default:
                return null;
        }
    };

    return (
        <Dialog
            open={loginFormOpen}
            fullWidth
            maxWidth="xs"
            onClose={handleClose}
            sx={{ pt: 0 }}
        >
            <DialogTitle>
                {[1, 2, 5].includes(activeStep) && (
                    <Button
                        sx={{
                            alignSelf: 'flex-start',
                            pt: 1,
                            position: 'absolute',
                            left: 10,
                            top: 10,
                        }}
                        color="inherit"
                        onClick={handleBack}
                        startIcon={<Iconify icon="eva:arrow-ios-back-fill" />}
                    >
                        Back
                    </Button>
                )}
                {(activeStep === 4 || activeStep === 2 || activeStep === 1 || activeStep === 0) && (
                    <Stack spacing={2} alignItems="center">
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                        }}
                    >
                        <DynamicAspectRatioImage
                            size={mdUp ? 80 : 60}
                            uri={config?.organization?.metadata?.logo}
                        />
                    </Box>
                    <Typography variant="subtitle2" color="text.secondary" component={"span"}>{config?.organization?.name}</Typography>
                    </Stack>
                )}
                {activeStep !== 4 && (
                    <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="h5">{stepTitles[activeStep]}</Typography>
                    </Stack>
                )}
                {activeStep === 4 && (
                    <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="h5">Terms and Conditions</Typography>
                    </Stack>
                )}
            </DialogTitle>
            <DialogContent sx={{ 
                padding: 0, 
                margin: 0, 
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary 
            }}>
                {renderStepContent()}
                <Backdrop
                    sx={{
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                        color: theme.palette.primary.contrastText,
                    }}
                    open={isLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </DialogContent>
            <DialogActions sx={{ backgroundColor: theme.palette.background.paper }}>
                <Box sx={{ width: '100%', textAlign: 'center' }}>
                    <KridaPowered />
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default LoginForm;