import { useRef, forwardRef, useImperativeHandle } from 'react';
import { Box } from '@mui/material';
import { useSelector } from '../../../redux/store';
import useRegistration from './useRegistration';
import UserCard from './UserCard';
import DynamicForm from '../../../components/dynamic-form/DynamicForm';
import Scrollbar from '../../../components/Scrollbar';

const BasicDetails = forwardRef((_, ref) => {
  const { currentPlayer } = useSelector((state) => state.user);
  const { formData, eventId } = useSelector((state) => state.eventRegistration);
  const { updateFormData, nextStep } = useRegistration();
  const formRef = useRef(null);
  const { registerData } = useSelector((state) => state.event);

  // Get registration questions from metadata
  const registrationQuestions = registerData?.metadata?.registrationQuestions || [];

  useImperativeHandle(ref, () => ({
    onNextStep: () => {
      if (formRef.current) {
        formRef.current.submitForm();
      }
    },
  }));

  const onSubmit = (data) => {
    updateFormData(data);
    nextStep();
  };
  console.log("currentPlayer in basic details", currentPlayer);

  // Map registration questions to form fields
  const formFields = registrationQuestions.map(question => ({
    name: question.name,
    type: question.type,
    label: question.label,
    placeholder: question.placeholder,
    validations: question.validations,
    options: question.options, // For select type questions
  }));

  return (
    <Scrollbar sx={{ flexGrow: 1 }}>
      <Box
        sx={(theme) => ({
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
          },
          gap: theme.spacing(2),
          mb: theme.spacing(2),
        })}
      >
        <UserCard user={currentPlayer} />
        <DynamicForm
          ref={formRef}
          formName={`basic-details-${eventId}`}
          onSubmit={onSubmit}
          showSubmitButton={false}
          defaultValues={formData || {}}
          schema={{
            name: 'player_form',
            layout: 'single-column',
            groups: [
              {
                name: 'performance_info',
                label: { en: 'Performance Information' },
                fields: formFields.map(field => field.name), // Use all field names
              },
            ],
            fields: formFields, // Use mapped fields
          }}
        />
      </Box>
    </Scrollbar>
  );
});

export default BasicDetails;