// useRegistration.js
import { useCallback, useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { registerEvent } from '../../../redux/slices/eventDetails';
import { useDispatch, useSelector } from '../../../redux/store';
import { 
    updateFormData as updateFormDataAction,
    resetFormData,
    setCurrentStepById,
    updateSteps,
    setEventId as setEventIdAction,
    setSlug as setSlugAction,
    setTournamentData as setTournamentDataAction,
    searchNumber,
    getPartnerHistory,
    setTournamentData
} from '../../../redux/slices/tournamentRegistrationSlice';
import { makePayment } from '../../../redux/slices/registration';


const useRegistration = () => {
    const dispatch = useDispatch();
    const { formData, tournamentData, eventId } = useSelector((state) => state.eventRegistration);
    const { currentPlayer } = useSelector((state) => state.user);
    const tournamentRegistration = useSelector((state) => state.eventRegistration);
    const { eventDetailsData } = useSelector((state) => state.eventDetails);
    const { enqueueSnackbar } = useSnackbar();
    const [invoiceId, setInvoiceId] = useState(null);
    const { paymentData } = useSelector((state) => state.registration);
    const navigate = useNavigate();
    const { registerData  } = useSelector((state) => state.event);
    

    // Initialize steps if they don't exist
    const { stepper = { currentStepId: 'basic-details', steps } } = tournamentRegistration || {};
    const { currentStepId, steps } = stepper;

    const getCurrentStepIndex = useCallback(() => 
        steps.findIndex(step => step.id === currentStepId)
    , [currentStepId, steps]);

    // Memoize navigation functions to prevent unnecessary re-renders
    const nextStep = useCallback(() => {
        const currentIndex = getCurrentStepIndex();
        if (currentIndex < steps.length - 1) {
            dispatch(setCurrentStepById(steps[currentIndex + 1].id));
        }
    }, [dispatch, getCurrentStepIndex, steps]);
    const prevStep = useCallback(() => {
        const currentIndex = getCurrentStepIndex();
        if (currentIndex > 0) {
            dispatch(setCurrentStepById(steps[currentIndex - 1].id));
        }
    }, [dispatch, getCurrentStepIndex, steps]);

    // Memoize form data updates
    const updateFormData = useCallback((data) => {
        dispatch(updateFormDataAction(data));
    }, [dispatch]);

    const updateTournamentData = useCallback((data) => {
        dispatch(setTournamentDataAction(data));
    }, [dispatch]);

    // Memoize reset function
    const resetData = useCallback(() => {
        dispatch(resetFormData());
    }, [dispatch]);

    // Handle payment response
    useEffect(() => {
        if (Object.keys(paymentData)?.length) {
            const gateway = paymentData?.gateway;
            
            if (gateway === 'phonepe') {
                const redirectionURL = paymentData?.gatewayData?.redirectInfo?.url;
                window.location.href = redirectionURL;
            } 
            else if (gateway === 'razorpay') {
                const gatewayData = paymentData?.gatewayData;
                const gatewayOrderId = gatewayData?.gatewayOrderId;
                const payload = {
                    ...gatewayData,
                    order_id: gatewayOrderId,
                    handler: () => {
                        navigate(0);
                    }
                };
                const razorPay = new window.Razorpay(payload);
                razorPay.open();
            }
            else if (gateway === 'cc_avenue') {
                const gatewayData = paymentData?.gatewayData;
                const form = document.createElement('form');
                form.id = 'nonseamless';
                form.method = 'post';
                form.name = 'redirect';
                form.action = gatewayData.authUrl;

                const encRequestInput = document.createElement('input');
                encRequestInput.type = 'hidden';
                encRequestInput.id = 'encRequest';
                encRequestInput.name = 'encRequest';
                encRequestInput.value = gatewayData?.encData;

                const accessCodeInput = document.createElement('input');
                accessCodeInput.type = 'hidden';
                accessCodeInput.name = 'access_code';
                accessCodeInput.id = 'access_code';
                accessCodeInput.value = gatewayData.accessCode;

                form.appendChild(encRequestInput);
                form.appendChild(accessCodeInput);

                document.body.appendChild(form);
                form.submit();
            }
        }
    }, [paymentData]);

    // Memoize registration submission
    const submitRegistration = useCallback(async (enqueueSnackbar, navigate, paymentMethod) => {
        if (!currentPlayer) {
            enqueueSnackbar('Player information not found', { variant: 'error' });
            return;
        }

        try {
            if (!invoiceId) {
                const registerUserData = {
                    payload: {
                        eventId: registerData?.id,
                        subEvents: eventDetailsData?.category === 'tournament' ? 
                        tournamentData.selectedEvents.map((subEventId) => {
                            const subEvent = eventDetailsData?.sub_events?.find(e => e.id === subEventId);
                            
                            return {
                                id: subEventId,
                                players: subEvent.isTeamEvent ? [
                                    {
                                        id: currentPlayer.id,
                                        formData
                                    },
                                    tournamentData.partnerDetails[subEventId] && {
                                        ...(tournamentData.partnerDetails[subEventId]?.id ? { id: tournamentData.partnerDetails[subEventId].id } : tournamentData.partnerDetails[subEventId])
                                    }
                                ] : [
                                    {
                                        id: currentPlayer.id,
                                        formData
                                    }
                                ]
                            };
                        }) : [{
                            id: registerData?.sub_events?.[0]?.id,
                            players: [{
                                id: currentPlayer.id,
                                formData: {
                                    formData
                                }
                            }]
                        }],
                        playerId: currentPlayer.id,
                    },
                    enqueueSnackbar
                };
                
                const response = await dispatch(registerEvent(registerUserData)).unwrap();
                if (response?.data?.invoiceId) {
                    const newInvoiceId = response.data.invoiceId;
                    setInvoiceId(newInvoiceId);
                    return newInvoiceId;  // Return invoiceId but don't make payment yet
                }
            } 
            // If we have both invoiceId and paymentMethod, make payment
            else if (invoiceId && paymentMethod) {
                dispatch(makePayment({
                    invoiceId,
                    paymentMethod,
                    enqueueSnackbar
                }));
            }
        } catch (error) {
            enqueueSnackbar(error?.message || 'Registration failed', { variant: 'error' });
        }
    }, [currentPlayer, dispatch, eventDetailsData, formData, tournamentData, eventId, invoiceId]);

    const updateStepsState = useCallback((newSteps) => {
        dispatch(updateSteps(newSteps));
    }, [dispatch]);

    const setEventId = useCallback((eventId) => {
        dispatch(setEventIdAction(eventId));
    }, [dispatch]);

    const setSlug = useCallback((slug) => {
        dispatch(setSlugAction(slug));
    }, [dispatch]);

    const searchPartnerByNumber = useCallback((nationalNumber) => {
        if (nationalNumber.length === 10) {
            dispatch(searchNumber(nationalNumber));
        }
    }, [dispatch]);

    const fetchPartnerHistory = useCallback((playerId) => {
        dispatch(getPartnerHistory(playerId));
    }, [dispatch]);

    const handleEventSelection = useCallback((event, eventDetailsData, tournamentData) => {
        if (tournamentData.selectedEvents?.includes(event.id)) {
            // Remove event
            dispatch(setTournamentData({
                selectedEvents: tournamentData.selectedEvents.filter((id) => id !== event.id),
                partnerDetails: {
                    ...tournamentData.partnerDetails,
                    [event.id]: undefined
                }
            }));
            return { success: true };
        } 
            // Check max events limit
            if (tournamentData.selectedEvents?.length >= eventDetailsData?.config?.maxSubEventRegistraionAllowed) {
                return { 
                    success: false, 
                    error: `You can only select up to ${eventDetailsData?.config?.maxSubEventRegistraionAllowed} events` 
                };
            }

            // Add event if it's not a team event
            if (!event.isTeamEvent && event.type !== 'team_of_two') {
                dispatch(setTournamentData({
                    selectedEvents: [...(tournamentData.selectedEvents || []), event.id]
                }));
                return { success: true };
            }

            // Return for team events to handle partner dialog
            return { success: true, requiresPartner: true };
        
    }, [dispatch]);

    const submitPartnerDetails = useCallback((currentEvent, partnerData, tournamentData) => {
        dispatch(setTournamentData({
            selectedEvents: [...(tournamentData.selectedEvents || []), currentEvent.id],
            partnerDetails: {
                ...tournamentData.partnerDetails,
                [currentEvent.id]: partnerData
            }
        }));
    }, [dispatch]);

    return {
        currentStep: getCurrentStepIndex(),
        nextStep,
        prevStep,
        updateFormData,
        updateTournamentData,
        resetData,
        submitRegistration,
        updateStepsState,
        setEventId,
        setSlug,
        invoiceId,
        searchPartnerByNumber,
        fetchPartnerHistory,
        partnerSearch: useSelector((state) => state.eventRegistration.partnerSearch),
        partnerHistory: useSelector((state) => state.eventRegistration.partnerHistory),
        loading: useSelector((state) => state.eventRegistration.loading),
        error: useSelector((state) => state.eventRegistration.error),
        handleEventSelection,
        submitPartnerDetails,
    };
};

export default useRegistration;