import React from "react";
import { 
  Modal, 
  Typography, 
  Stack, 
  RadioGroup, 
  FormControlLabel, 
  Radio 
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';

const PaymentDialog = ({ isOpen, onClose, paymentMethod, setPaymentMethod, onSubmit, smUp }) => {
  const theme = useTheme();
  return (
    <Modal 
      sx={{ display: 'flex', alignItems: 'center' }} 
      open={isOpen} 
      onClose={onClose}
    >
      <Stack sx={{
        alignItems: "center",
        justifyContent: "flex-start",
        backgroundColor: theme.palette.background.paper,
        width: smUp ? "420px" : "360px",
        minHeight: 240,
        my: "auto",
        mx: "auto",
        px: "2rem",
        borderRadius: "4px",
        position: 'relative',
      }}>
        <Typography variant='h5' sx={{ textAlign: 'center', my: 2 }}>
          Payment options
        </Typography>
        
        <RadioGroup
          aria-label="payment method"
          name="payment-method"
          value={paymentMethod}
          onChange={(e) => setPaymentMethod(e.target.value)}
          sx={{ width: '100%' }}
        >
          <FormControlLabel 
            sx={{ 
              mb: 2,
              p: 1,
              border: '1px solid',
              borderColor: paymentMethod === 'upi' ? 'primary.main' : 'divider',
              borderRadius: 1,
              width: '100%'
            }} 
            value="upi" 
            control={<Radio />} 
            label={
              <>
                <Typography>Pay via UPI</Typography>
                <Typography variant='caption'>
                  Convenience Fee: <span style={{ fontWeight: '800' }}>0%</span>
                </Typography>
              </>
            } 
          />

          <FormControlLabel 
            sx={{ 
              p: 1,
              border: '1px solid',
              borderColor: paymentMethod === 'non_upi' ? 'primary.main' : 'divider',
              borderRadius: 1,
              width: '100%'
            }}
            value="non_upi" 
            control={<Radio />} 
            label={
              <>
                <Typography>
                  Pay via Debit card / Credit card/ Online Banking
                </Typography>
                <Typography variant='caption'>
                  Convenience Fee: <span style={{ fontWeight: '800' }}>2% + GST</span>
                </Typography>
              </>
            } 
          />
        </RadioGroup>

        <LoadingButton 
          sx={{ width: '100%', mb: 3, mt: 2 }} 
          fullWidth 
          size="large" 
          type="submit" 
          variant="contained"
          onClick={onSubmit}
        >
          Pay Now
        </LoadingButton>
      </Stack>
    </Modal>
  );
}

export default PaymentDialog; 