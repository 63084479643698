import React from 'react';
import { Box, Paper, Typography, Stack, Link } from '@mui/material';
import Iconify from '../../components/Iconify';
import useResponsive from '../../hooks/useResponsive';

const TournamentVenue = ({ data }) => {
  const mdUp = useResponsive('up', 'md');
  
  const venueName = data.props?.find(p => p.key === 'subtitle')?.data;
  const venueAddress = data.props?.find(p => p.key === 'address')?.data;
  const viewMapAction = data.actions[0];

  return (
    <Paper variant="elevation" elevation={12}>
      <Box p={3}>
        <Typography 
          variant="subtitle2"
          color="text.secondary"
          align="center"
          sx={{ mb: 1 }}
        >
          Venue
        </Typography>
        <Stack spacing={2}>
          <Stack spacing={1} alignItems="center">
            <Typography 
              variant={mdUp ? "h6" : "subtitle1"} 
              sx={{ fontWeight: '600' }}
              align="center"
            >
              {venueName}
            </Typography>
            <Typography 
              variant="body2" 
              color="text.secondary"
              align="center"
            >
              {venueAddress}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={0.5}>
            <Iconify icon={'fluent:open-16-regular'} width={16} height={16} />
            <Link
              href={viewMapAction?.url || '#'}
              target="_blank"
              rel="noopener"
              underline="none"
              color="inherit"
            >
              <Typography variant="body2">
                {viewMapAction?.label}
              </Typography>
            </Link>
          </Stack>
        </Stack>
      </Box>
    </Paper>
  );
};

export default TournamentVenue;
