import React from 'react';
import { Box, Paper, Typography, Stack, Grid } from '@mui/material';
import useResponsive from '../../hooks/useResponsive';
import DynamicAspectRatioImage from '../../components/DynamicAspectRatioImage';

const TournamentOrg = ({ data }) => {
  const mdUp = useResponsive('up', 'md');
  const organizationName = data.props.find((p) => p.key === 'subtitle')?.data;
  const organizationLogo = data.props.find((p) => p.key === 'logo')?.data;

  return (
    <Paper variant="elevation" elevation={12}>
      <Box p={3}>
        <Typography 
          variant="subtitle2"
          color="text.secondary"
        >
          Organised by
        </Typography>
        <Grid 
          container 
          alignItems="center" 
          spacing={{ xs: 1, md: 2 }}
        >
          <Grid item>
            <DynamicAspectRatioImage 
              size={mdUp ? 60 : 40}
              uri={organizationLogo} 
            />
          </Grid>
          <Grid item xs>
            <Typography 
              variant={mdUp ? "h6" : "subtitle1"} 
              sx={{ fontWeight: '600' }} 
              align="start"
            >
              {organizationName}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default TournamentOrg;
