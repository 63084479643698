import React from 'react';
import { Box, Paper, Typography, Grid, Chip, Stack, Divider, Tooltip } from '@mui/material';
import Iconify from '../../components/Iconify';
import { fDate, fCurrency } from '../../utils/formatNumber';

const CategoryBox = ({ children }) => (
    <Chip
        label={children}
        color='secondary'
    />
);

const EventBox = ({ children, startDate, endDate, price }) => (
    <Tooltip
        title={
            <Box sx={{ p: 1 }}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Iconify icon="mdi:calendar-month" width={16} height={16} />
                    <Typography variant="caption">
                        {startDate && endDate ? `${fDate(startDate)} to ${fDate(endDate)}` : 'Dates not available'}
                    </Typography>
                </Stack>
                <br />
                <Stack direction="row" spacing={1} alignItems="centers">
                    <Iconify icon="mdi:currency-inr" width={16} height={16} />
                    <Typography variant="caption">
                        Price: {price || 'Price not available'}
                    </Typography>
                </Stack>
            </Box>
        }
        sx={{
            bgcolor: 'rgba(0, 0, 0, 0.87)'
        }}
    >
        <Chip
            label={children}
            color='secondary'
            variant="soft"
        />
    </Tooltip>
);

const CategoryRow = ({ category, events }) => (
    <Box mb={1}>
        <Grid sx={{}} flexDirection="row" flexWrap="nowrap" container alignItems="center" spacing={2}>
            <Grid item>
                <CategoryBox>{category}</CategoryBox>
            </Grid>
            <Grid item xs>
                <Box display="flex" flexWrap="nowrap" gap={.5} sx={{ overflowX: 'auto' }}>
                    {events.map((event, index) => (
                        <EventBox
                            key={index}
                            startDate={event.startDate}
                            endDate={event.endDate}
                            price={event.price}
                        >
                            {event.shortCode}
                        </EventBox>
                    ))}
                </Box>
            </Grid>
        </Grid>
        <Divider sx={{ my: 1.5 }} />
    </Box>
);

const TournamentSubEvents = ({ data }) => {

    const subEvents = data?.props?.find(p => p.key === 'subEvent')?.data;

    return (
        <Paper sx={{ width: '100%', p: 3 }}>
            <Typography variant="h6" fontWeight={600} sx={{ mb: 2 }}>
                Events
            </Typography>
            {subEvents?.map((event, index) => (
                <CategoryRow
                    key={event.id || index}
                    category={event.ageGroup}
                    events={[{
                        shortCode: event.name,
                        type: event.type,
                        startDate: event.startDate,
                        endDate: event.endDate,
                        price: event.priceConfig.price,
                        ageGroup: event.ageGroup
                    }]}
                />
            ))}
        </Paper>
    );
};

export default TournamentSubEvents; 