import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios'
import { API_STATUS, KRIDA_API } from '../../config';

const initialState = {
    landingData: {},
    landingLoader: 'idle',
    error: {},
}

const landingSlice = createSlice({
    name: 'landing',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getLandingData.pending, (state) => {
                state.landingLoader = 'loading';
            })
    }
})

export const getLandingData = createAsyncThunk('landing/getLandingData', async (slug) => {
    try {
        const token = localStorage.getItem('accessToken');
        const response = await axios.get(`admin/landing_page/pages/by-slug/${slug}`, {
            headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
    } catch (error) {
        console.log(error);
    }
})

export default landingSlice.reducer;
