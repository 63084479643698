import React, { forwardRef } from 'react';
import { styled } from '@mui/material/styles';
import {
    Box,
    Button,
    Grid,
    Card,
    Container,
    Typography,
    Stack,
    Tab,
    List,
    Divider,
    ListItem,
    ListItemText,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EventIcon from '@mui/icons-material/Event';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { Helmet } from 'react-helmet-async';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import About from './event-details/About'
import Timeline from './event-details/Timeline'
import Faq from './event-details/Faq'
import TabsValue from './event-details/Tabs'
import Hero from './event-details/Hero'
import Eligibility from './event-details/Eligibility'
import Highlights from './event-details/Highlights';
import MoreEvents from './event-details/MoreEvents';

const ComponentFactory = forwardRef(({ type, componentData, allRefs, visibleComponents}, ref) => {
    const tabs = visibleComponents
        ?.filter(item => !['hero', 'tabs'].includes(item.type?.toLowerCase()))
        .map(item => ({
            value: item.type?.toLowerCase(),
            label: item.type?.split(' ').map(word => 
                word.charAt(0).toUpperCase() + word.slice(1)
            ).join(' '),
            ref: allRefs[item.type]
        }));

    switch(type?.toLowerCase()) {
        case 'about':
            return <About about={componentData.data} ref={ref} />
        case 'timeline':
            return <Timeline timeline={componentData.data} ref={ref}     />
        case 'comp_faq':
            return <Faq faq={componentData.data} ref={ref} />
        case 'tabs':
            return <TabsValue 
                refs={allRefs} 
                tabs={tabs}
                scrollToSection={(ref) => {
                    ref.current?.scrollIntoView({ behavior: 'smooth' });
                }}
            />;
        case 'hero':
             return <Hero config={componentData.data} />
        case 'highlights':
            return <Highlights highlights={componentData.data} ref={ref}/>
        case 'comp_eligibility':
            return <Eligibility eligibilityData={componentData.data} ref={ref} />
        case 'more events':
            return <MoreEvents moreEvents={componentData.data} ref={ref} />
        default:
            return null;
    }      
})

export default ComponentFactory
