import { Card, Stack, Typography, ToggleButton, ToggleButtonGroup, Paper, Collapse, Box, Chip } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useState } from 'react';
import { useSelector } from 'react-redux';

const EventFilters = ({ filters, onFilterChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { eventDetailsData } = useSelector((state) => state.eventDetails);

  const handleFilterChange = (filterType, newValue) => {
    if (newValue !== null) {
      onFilterChange(filterType, newValue);
    }
  };

  const filterGroups = [
    {
      label: 'Age Category',
      type: 'ageGroup',
      value: filters.ageGroup,
      options: [...new Set(eventDetailsData?.sub_events?.map(event => event.ageGroup) || [])].sort(),
      formatOption: (opt) => opt.toUpperCase()
    },
    {
      label: 'Gender',
      type: 'gender',
      value: filters.gender,
      options: [...new Set(eventDetailsData?.sub_events?.map(event => event.gender) || [])].sort(),
      formatOption: (opt) => opt.toUpperCase()
    },
    {
      label: 'Type',
      type: 'type',
      value: filters.type,
      options: [...new Set(eventDetailsData?.sub_events?.map(event => event.type) || [])],
      formatOption: (opt) => opt === 'team_of_1' ? 'Singles' : 'Doubles'
    }
  ];

  const getChipLabel = (key, value) => {
    if (key === 'type') {
      return value === 'team_of_1' ? 'Singles' : 'Doubles';
    }
    return value.toUpperCase();
  };

  return (
    <Box sx={{ mx: 2 }}>
      <Stack 
        direction="row" 
        alignItems="center" 
        spacing={0.5}
        sx={{ width: 'fit-content', p: '4px 8px', cursor: 'pointer', borderRadius: '8px', 
              bgcolor: 'background.neutral' }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <FilterAltIcon sx={{ color: 'text.primary', fontSize: 14 }} />
        <Typography variant="caption" sx={{ color: 'text.primary', fontSize: '0.75rem', opacity: 0.8 }}>
          Filters
        </Typography>
      </Stack>

      <Paper sx={{ 
        borderRadius: 2, 
        bgcolor: 'background.neutral', 
        mt: 0.5, 
        width: 'fit-content', 
      }}>
        <Collapse in={isOpen}>
          <Box sx={{ p: 1 }}>
            <Stack spacing={0.75}>
              {filterGroups.map(({ label, type, value, options, formatOption }) => (
                <Stack key={type} spacing={1}>
                  <Typography 
                    variant="caption" 
                    sx={{ 
                      color: 'text.secondary',
                      fontSize: '0.75rem',
                      lineHeight: 1,
                    }}
                  >
                    {label}
                  </Typography>
                  <ToggleButtonGroup
                    value={value}
                    exclusive
                    onChange={(_, newValue) => handleFilterChange(type, newValue)}
                    size="small"
                    sx={{ 
                      width: '100%',
                      '& .MuiToggleButton-root': { 
                        py: 0.25,
                        px: 0.5,
                        minWidth: 0,
                        fontSize: '0.75rem',
                        fontWeight: 500,
                        color: 'text.secondary',
                        '&.Mui-selected': {
                          bgcolor: 'primary.neutral',
                          color: 'primary.contrastText',
                        }
                      } 
                    }}
                  >
                    {options.map((option) => (
                      <ToggleButton key={option} value={option}>
                        {formatOption(option)}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Stack>
              ))}
            </Stack>
          </Box>
        </Collapse>

        {!isOpen && Object.entries(filters).some(([key, value]) => value !== 'all') && (
          <Stack direction="row" spacing={1} sx={{ p: 1 }}>
            {Object.entries(filters).map(([key, value]) => 
              value !== 'all' && (
                <Chip 
                  key={key}
                  label={getChipLabel(key, value)}
                  size="small"
                  onDelete={() => onFilterChange(key, 'all')}
                />
              )
            )}
          </Stack>
        )}
      </Paper>
    </Box>
  );
};

export default EventFilters; 