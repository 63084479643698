import React from 'react';
import { Container, Box, Typography, Divider, Stack, Card, Paper } from '@mui/material';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent } from '@mui/lab';
import { Icon } from '@iconify/react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import Iconify from '../../components/Iconify';
import { fDate } from '../../utils/formatNumber';
import useResponsive from '../../hooks/useResponsive';

const TimelineEvent = ({ icon, title, dateTime, isLast, }) => {
    const mdUp = useResponsive('up', 'md');
    return (
        <TimelineItem>
            <TimelineOppositeContent>
                <Typography 
                    variant={mdUp ? "subtitle1" : "subtitle2"}
                    align="right"
                >
                    {title}
                </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineDot color="primary">
                    <Iconify icon={icon} width={16} height={16} margin={.5} />
                </TimelineDot>
                {!isLast && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
                <Typography 
                    variant="body2" 
                    component="span" 
                    color="textSecondary"
                >
                    {dateTime}
                </Typography>
            </TimelineContent>
        </TimelineItem>
    )
};

const TournamentTimeline = ({ data }) => {
    const mdUp = useResponsive('up', 'md');
    
    return (
        <Paper sx={{ textAlign: 'left', width: '100%' }}>
            <Box sx={{ 
                py: 3,
                px: { xs: 2, md: 3 },
                display: 'flex', 
                justifyContent: 'start', 
                flexDirection: 'column' 
            }}>
                <Typography 
                    variant={mdUp ? "h6" : "subtitle1"}
                    color="text.secondary"
                    sx={{ mb: { xs: 1, md: 2 } }}
                >
                    Registration Timeline
                </Typography>
                <Timeline 
                    position="right"
                >
                    {data?.props?.find(p => p.key === 'timeline')?.data?.map((event, index) => (
                        <TimelineEvent
                            key={index}
                            icon={event.icon}
                            title={event.description}
                            dateTime={event.title}
                            isLast={index === data.props[0].data.length - 1}
                        />
                    ))}
                </Timeline>
            </Box>
        </Paper>
    );
};

export default TournamentTimeline;