import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment/moment';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Fab,
  alpha,
  Box,
  Tabs,
  Tab,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { parsePhoneNumber } from 'react-phone-number-input';
import { searchNumber,getPartnerHistory } from '../../../redux/slices/tournamentRegistrationSlice';
import { PhoneInput } from '../../../components/phone-input';
import { useSelector } from '../../../redux/store';
import Iconify from '../../../components/Iconify';
import AddNewPartnerDialog from './AddNewPartnerDialog';
import { PartnerSection } from './PartnerCards';
import Scrollbar from '../../../components/Scrollbar';
import useRegistration from './useRegistration';

  



 const PartnerDialog = ({ open, onClose, onSubmit, currentEvent }) => {
  const [mobileWithCode, setMobileWithCode] = useState('');
  const [mobile, setMobile] = useState('');
  const [dialCode, setDialCode] = useState('91');
  const { currentOrgProfiles, currentPlayer } = useSelector((state) => state.user);
  const [addNewProfile, setAddNewProfile] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [searchByNumber, setSearchByNumber] = useState('');
  const [currentTab, setCurrentTab] = useState('profiles');

  const { 
    searchPartnerByNumber, 
    fetchPartnerHistory,
    partnerSearch,
    partnerHistory,
    loading,
    error 
  } = useRegistration();

  const {
    handleSubmit
  } = useForm();

  useEffect(() => {
    if (mobileWithCode) {
      const phoneNumber = parsePhoneNumber(mobileWithCode);
      if (phoneNumber) {
        setMobile(phoneNumber.nationalNumber);
        setDialCode(phoneNumber.countryCallingCode);
      }
    } else {
      setMobile('');
    }
  }, [mobileWithCode]);

  const onSubmitForm = () => {
    if (selectedProfile) {
      onSubmit({
        id: selectedProfile.id,
        firstName: selectedProfile.firstName,
        lastName: selectedProfile.lastName,
      });
    }
  };


  const handleProfileSelect = (profile) => {
    if (selectedProfile?.id === profile.id) {
      setSelectedProfile(null);
    } else {
      setSelectedProfile(profile);
    }
  };

  const handleSearchByNumber = (value) => {
    const phoneNumber = parsePhoneNumber(value || '');
    const nationalNumber = phoneNumber?.nationalNumber || '';
    
    setSearchByNumber(value);
    searchPartnerByNumber(nationalNumber);
  };

useEffect(() => {
  fetchPartnerHistory(currentPlayer.id);
}, [currentPlayer.id, fetchPartnerHistory]);

const handleChangeTab = (event, newValue) => {
  setCurrentTab(newValue);
};

const TABS = [
  {
    value: 'profiles',
    label: 'Other Profiles',
    component: (
      <PartnerSection
        profiles={currentOrgProfiles?.filter((profile) => profile.id !== currentPlayer.id)}
        selectedProfile={selectedProfile}
        onProfileSelect={handleProfileSelect}
        title="My Other Profiles"
      />
    ),
    count: currentOrgProfiles?.filter((profile) => profile.id !== currentPlayer.id)?.length || 0,
  },
  {
    value: 'search',
    label: 'Search Results',
    component: (
      <PartnerSection
        profiles={partnerSearch}
        selectedProfile={selectedProfile}
        onProfileSelect={handleProfileSelect}
        title="Search Results"
      />
    ),
    count: partnerSearch?.length || 0,
  },
  {
    value: 'history',
    label: 'Previous Partners',
    component: (
      <PartnerSection
        profiles={partnerHistory}
        selectedProfile={selectedProfile}
        onProfileSelect={handleProfileSelect}
        title="My Previous Partners"
      />
    ),
    count: partnerHistory?.length || 0,
  },
].filter(tab => tab.count > 0);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Add Partner Details for {currentEvent?.name}
      </DialogTitle>
      <DialogContent sx={{ p: 0, height: '60vh' }}>
        <Box sx={{ px: 3, py: 2, height: '100%', display: 'flex', flexDirection: 'column' }}>
          <PhoneInput
            label="Search by number"
            value={searchByNumber}
            onChange={handleSearchByNumber}
            fullWidth
            variant="outlined"
            helperText="Enter 10 digits to search"
            sx={{
              mb: 3,
              '& .MuiOutlinedInput-root': {
                borderRadius: 2,
                bgcolor: (theme) => alpha(theme.palette.grey[500], 0.04),
              }
            }}
            inputProps={{
              autoFocus: true,
            }} />

          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
            <Tabs
              value={currentTab}
              onChange={handleChangeTab}
              variant="scrollable"
              scrollButtons={false}
              allowScrollButtonsMobile={false}
              sx={{
                px: 0,
                minHeight: 48,
                '& .MuiTabs-indicator': {
                  backgroundColor: 'primary.main',
                  height: 3,
                },
                '& .MuiTab-root': {
                  minHeight: 48,
                  minWidth: 'auto',
                  px: 3,
                  py: 1.5,
                  fontWeight: 600,
                  fontSize: '0.875rem',
                  textTransform: 'none',
                  '&:not(:last-of-type)': {
                    mr: 2,
                  },
                  '&.Mui-selected': {
                    color: 'primary.main',
                  },
                },
              }}
            >
              {TABS.map((tab) => (
                <Tab
                  key={tab.value}
                  value={tab.value}
                  label={tab.label}
                  icon={tab.count > 0 ? <Box component="span" sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    ml: 1,
                    width: 24,
                    height: 24,
                    fontSize: '0.75rem',
                    borderRadius: '50%',
                    bgcolor: 'primary.lighter',
                    color: 'primary.dark',
                  }}>
                    {tab.count}
                  </Box> : null}
                  iconPosition="right"
                />
              ))}
            </Tabs>
          </Box>

          <Scrollbar sx={{ flexGrow: 1 }}>
            <Box sx={{ flex: 1 }}>
              {TABS.find((tab) => tab.value === currentTab)?.component}
            </Box>
          </Scrollbar>

          {currentOrgProfiles?.length < 5 && (
            <Button
              variant='outlined'
              aria-label="add"
              onClick={() => {
                setAddNewProfile(!addNewProfile);
                setSelectedProfile(null);
              }}
              sx={{
                position: 'absolute',
                bottom: 80,
                right: 25,
                borderColor: 'primary.main',
                bgcolor: 'transparent',
                '&:hover': {
                  color: 'primary.lighter',
                  backgroundColor: 'primary.main',
                },
                boxShadow: (theme) => theme.shadows[8],
                zIndex: 1,
              }}
            >
              <Iconify icon="eva:plus-fill" width={24} height={24} />
              Add Partner
            </Button>
          )}
        </Box>

        {addNewProfile && !selectedProfile && (
          <AddNewPartnerDialog 
            open={addNewProfile} 
            onClose={() => setAddNewProfile(false)} 
            onSubmit={onSubmit} 
            searchedNumber={searchByNumber} 
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onSubmitForm()}
          variant="contained"
          fullWidth
          sx={{
            height: 48,
            borderRadius: 2,
            boxShadow: (theme) => theme.customShadows?.primary || theme.shadows[8],
          }}
        >
          Select Partner 
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PartnerDialog;
