import React from 'react';
import { Box, Paper, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const TournamentFaq = ({ data }) => {
  const faqItems = data?.props?.find(p => p.key === 'faq')?.data;
  return (
    <Paper
      sx={{
        p: { xs: 2, md: 4 },
        width: '100%',
        borderRadius: 2,
      }}
    >
      <Box>
        <Typography
          variant="h6"
          fontWeight={600}
          sx={{
            mb: 3,
            textAlign: 'left',
            color: 'text.primary',
            pl: 1,
          }}
        >
          FAQs
        </Typography>

        {faqItems?.map((faqItem, index) => (
            
          <Accordion
            key={index}
            sx={{
              mb: { md: 2 },
              '&:before': {
                display: 'none',
              },
              bgcolor: 'transparent',
              boxShadow: 'none',
              '& .MuiAccordionSummary-root': {
                borderRadius: 1,
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                '& .MuiTypography-root': {
                  fontWeight: 600,
                  color: 'text.primary',
                  textAlign: 'left',
                },
              }}
            >
              <Typography>{faqItem?.question}</Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                pl: { xs: 2, md: 3 },
                pr: 2,
                color: 'text.secondary',
              }}
            >
              <Typography align="left">{faqItem.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Paper>
  );
};

export default TournamentFaq;
