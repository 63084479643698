import React from 'react';
import { Box, Button, Paper, Stack, Typography, Grid, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Iconify from '../../components/Iconify';
import DynamicAspectRatioImage from '../../components/DynamicAspectRatioImage';
import CarouselV2 from '../../krida/carousel/carousel-v2';

const TournamentHero = ({ data, config, registrationData, mdUp }) => {
  const navigate = useNavigate();

  const infoData = data?.props?.find(p => p.key === 'list')?.data || [];
  const logo = data?.props?.find(p => p.key === 'logo')?.data;
  const eventName = data?.props?.find(p => p.key === 'title')?.data;
  const images = data?.props?.find(p => p.key === 'images')?.data || [];
  const description = data?.props?.find(p => p.key === 'description')?.data;
  const aspectRatio = data?.props?.find(p => p.key === 'images')?.aspectRatio || '1/1';
  const subtitle = data?.props?.find(p => p.key === 'subtitle')?.data;

  return (
    <Paper
      sx={{
        position: 'sticky',
        top: mdUp ? 64 : 48,
        bgcolor: 'background.paper',
        zIndex: 1,
      }}
    >
      <Container maxWidth="lg">
        <Box py={mdUp ? 4 : 2}>
          <Grid 
            container 
            spacing={3}
            alignItems={images?.length > 0 ? 'center' : 'flex-start'}
          >
            {/* Left Content */}
            <Grid 
              item 
              xs={12} 
              md={images.length > 0 ? 6 : 12}
              sx={{ 
                textAlign: { xs: 'center', md: 'left' },
                display: 'flex',
                flexDirection: 'column',
                justifyContent: images?.length > 0 ? 'center' : 'flex-start',
                height: '100%',
              }}
            >
              {/* Logo and Title */}
              <Grid container my={2} alignItems="center">
                {logo && (
                  <Grid item mr={2}>
                      <DynamicAspectRatioImage 
                        size={mdUp ? 80 : 48}
                        uri={logo}
                        sx={{ objectFit: 'cover' }}
                      />
                  </Grid>
                )}
                <Grid item xs>
                  <Typography 
                    variant={mdUp ? "h3" : "h5"} 
                    align={mdUp ? "left" : "center"}
                  >
                    {eventName}
                  </Typography>
                </Grid>
              </Grid>

              {/* Description */}
              {subtitle && (
                <Typography 
                  color="text.secondary" 
                  variant={mdUp ? 'body1' : 'body2'} 
                  mb={3}
                >
                  {subtitle}
                </Typography>
              )}

              {/* Info Items */}
              <Stack spacing={2} mb={3}>
                {infoData.map((item, index) => (
                  <Stack 
                    key={index} 
                    direction="row" 
                    spacing={2} 
                    alignItems="center"
                    sx={{
                      p: 1.5,
                      borderRadius: 1,
                      bgcolor: 'background.neutral',
                    }}
                  >
                    <Iconify
                      icon={item.icon}
                      sx={{
                        width: { xs: 20, md: 24 },
                        height: { xs: 20, md: 24 },
                        flexShrink: 0,
                      }}
                    />
                    <Typography 
                      variant={mdUp ? 'body2' : 'caption'}
                      color="text.primary"
                    >
                      {item.label}
                    </Typography>
                  </Stack>
                ))}
              </Stack>

              {/* Action Buttons */}
              <Stack 
                direction={mdUp ? 'row' : 'column'} 
                spacing={2}
              >
                {data.actions.map((action, index) => (
                  <Button
                    key={index}
                    variant={action.variant}
                    size="large"
                    color={action.color}
                    fullWidth={!mdUp}
                    onClick={() => {

                        const externalUrl = action.url;
                        window.location.replace(externalUrl);
                    }}
                    disabled={action.type === 'register' && registrationData?.count > 0 || action.isDisabled}
                    startIcon={action.type === 'register' && registrationData?.count > 0 ? <CheckCircleIcon /> : null}
                    sx={(theme) => ({ 
                      py: 2,
                      px: { xs: 3, md: 4 },
                      minWidth: { md: theme.spacing(30) },
                      fontSize: {
                        xs: theme.typography.button.fontSize,
                        md: theme.typography.h6.fontSize
                      },
                      height: { 
                        xs: theme.spacing(6),
                        md: theme.spacing(7)
                      },
                      textTransform: 'none',
                      ...(mdUp && {
                        flex: images?.length > 0 ? 1 : 'initial',
                        maxWidth: images?.length > 0 ? 'none' : theme.spacing(32),
                      })
                    })}
                  >
                    {action.type === 'register' && registrationData?.count > 0 
                      ? 'Already Registered' 
                      : action.label}
                  </Button>
                ))}
              </Stack>
            </Grid>

            {/* Right Content - Carousel */}
            {images?.length > 0 && (
              <Grid 
                item 
                xs={12} 
                md={6}
                sx={{
                  mb: { xs: 3, md: 0 },
                  order: { xs: -1, md: 2 }
                }}
              >
                <CarouselV2
                  data={images.map(img => ({
                    ...img,
                    ratio: aspectRatio
                  }))}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
    </Paper>
  );
};

export default TournamentHero;
